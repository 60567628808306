import { DurationInput } from "../../../components/DurationField";
import { InputField } from "../../../components/InputField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Col, Grid } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";
import { CrudTheme } from "../ui/CrudTheme";

export const FormImpro = () => {
  const { data, actions } = useLocalstorageProvider();

  const matchData = data?.matchData;

  return (
    <>
      <SectionTitle>Match</SectionTitle>
      <Grid>
        <Col md={3}>
          <DurationInput
            buttonIncrement={15}
            minValue={15}
            maxValue={23 * 60 * 60 - 1}
            label="Durée période"
            value={matchData?.duration || 45 * 60}
            onChange={(value) => actions.handleMatchChange(value, "duration")}
          />
        </Col>
        <Col md={3}>
          <InputField
            value={matchData?.nb_periods || "2"}
            label="Nombre de périodes"
            type="number"
            handleChange={(e: any) => actions.handleMatchChange(e.target.value, "nb_periods")}
          />
        </Col>
        <Col md={3}>
          <DurationInput
            buttonIncrement={15}
            minValue={15}
            maxValue={60 * 60 - 1}
            label="Durée pause"
            value={matchData?.pause_duration || 15 * 60}
            onChange={(value) => actions.handleMatchChange(value, "pause_duration")}
          />
        </Col>
        <Col md={3}>
          <DurationInput
            buttonIncrement={15}
            minValue={15}
            maxValue={60 * 60 - 1}
            label="Durée caucus"
            value={matchData?.caucus_duration || 20}
            onChange={(value) => actions.handleMatchChange(value, "caucus_duration")}
          />
        </Col>
      </Grid>
      <SectionTitle>Thèmes</SectionTitle>
      <Grid>
        <Col md={12}>
          <CrudTheme />
        </Col>
      </Grid>
    </>
  );
};
