import { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Administration from "./views/Administration";
import DisplayScreen from "./views/DisplayScreen";

import { lightTheme, darkTheme } from "./styles/theme.config";
import { GlobalStyles } from "./styles/theme.global";
import { useLocalstorageProvider } from "./hooks/LocalstorageProvider";

export const Routing = () => {
  const [darkMode, setDarkMode] = useState(true);
  const { data } = useLocalstorageProvider();

  useEffect(() => {
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkMode(prefersDarkScheme.matches);
  }, []);

  const themeData = useMemo(() => {
    return {
      //...(darkMode ? darkTheme : lightTheme),
      ...(darkMode ? darkTheme : darkTheme),
      custom: data?.screenData ? data?.screenData?.theme : {},
    };
  }, [data]);

  return themeData ? (
    <ThemeProvider theme={(themeData || {}) as any}>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Administration />} />
          <Route path="/:id/:token" element={<Administration />} />
          <Route path="/livescore" element={<DisplayScreen />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  ) : null;
};
