import { Col, Grid } from "../../../styles/components/Grid"
import { SectionTitle } from "../styles/SectionStyles"
import { TeamAdminEdit } from "../ui/TeamAdminEdit"

export const FormTeams = () => {
  return (
    <>
      <SectionTitle>Équipes</SectionTitle>
      <Grid>
        <Col md={6}>
          <TeamAdminEdit teamId="team_left" />
        </Col>
        <Col md={6}>
          <TeamAdminEdit teamId="team_right" />
        </Col>
      </Grid>
    </>
  )
}