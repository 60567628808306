import { useState } from "react";
import styled from "styled-components";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { pixToRem } from "../../../styles/theme.mixins";
import { TeamMemberType } from "../../../type";

export const PresenceCheck = ({
  teamId,
}: {
  teamId: "team_left" | "team_right";
}) => {
  const { data, actions } = useLocalstorageProvider();

  const matchData = data?.matchData;
  const screenData = data?.screenData;

  const [members, setMembers] = useState<any>(
    screenData?.[`presence_${teamId}`]
  );

  const checkMember = (e: any) => {
    const itemIndex = members?.indexOf(e.target.value);
    let checklist = [];
    if (itemIndex !== -1) {
      checklist = members.filter((item: string) => item !== e.target.value);
    } else {
      checklist = [...members, e.target.value];
    }

    setMembers(checklist);
    actions.handleScreenChange(checklist, `presence_${teamId}`);
  };

  return (
    <Wrapper>
      <strong>
        Equipe {teamId === "team_left" ? "A" : "B"} :{" "}
        {matchData?.[teamId]?.name}
      </strong>
      <br />
      <br />
      {matchData?.[teamId]?.members?.map(
        (member: TeamMemberType, index: number) => (
          <Row key={`presence-${teamId}-${index}`}>
            <input
              id={`presence-${teamId}-${index}`}
              type="checkbox"
              value={member?.role +" - "+ member?.name}
              checked={members?.includes(member?.role +" - "+ member?.name)}
              onChange={(e: any) => checkMember(e)}
            />
            {` `}
            <label htmlFor={`presence-${teamId}-${index}`}>
            {member?.id} - {member?.name} - {member?.role}
            </label>
          </Row>
        )
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${pixToRem(10, 0)};
`;

const Row = styled.div`
  padding: ${pixToRem(5, 0)};
`;
