import { useEffect, useState } from "react";
import styled from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";

import { ThemeKey, ThemeType } from "../../../type";

import { InputField } from "../../../components/InputField";
import { Col, Grid } from "../../../styles/components/Grid";
import {
  initialImproData,
  useLocalstorageProvider,
} from "../../../hooks/LocalstorageProvider";
import {
  LabelStyled,
  SelectField,
  SelectStyled,
} from "../../../components/SelectField";
import { THEME_TYPE_LABELS, THEME_YES_NO } from "../../../constants";
import { useAppProvider } from "../../../hooks/AppProvider";
import { DurationInput } from "../../../components/DurationField";
import { durationToString } from "../../../helpers/timeFormat";

export const CrudForm = ({
  data,
  nextId,
  submitForm,
}: {
  data: ThemeType;
  nextId: number;
  submitForm: any;
}) => {
  const [id, setId] = useState<number>(data.id);
  const [theme, setTheme] = useState<string>(data.theme);
  const [category, setCategory] = useState<string>(data.category);
  const [type, setType] = useState<string>(data.type);
  const [nbPlayer, setNbPlayer] = useState<string>(data.nbPlayer);
  const [duration, setDuration] = useState<number>(data.duration);
  const [useChrono, setUseChrono] = useState<string>(data.useChrono);
  const [useChrono_caucus, setUseChronoCaucus] = useState<string>(
    data.useChrono_caucus
  );

  const [themeConfig, setThemeConfig] = useState<ThemeType>();

  useEffect(() => {
    setThemeConfig({
      id: id,
      theme: theme,
      category: category,
      type: type,
      nbPlayer: nbPlayer,
      duration: duration,
      useChrono: useChrono,
      useChrono_caucus: useChrono_caucus,
    });
  }, [
    id,
    theme,
    category,
    type,
    nbPlayer,
    duration,
    useChrono,
    useChrono_caucus,
  ]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setId(data.id || nextId);
      setTheme(data.theme);
      setCategory(data.category);
      setType(data.type);
      setNbPlayer(data.nbPlayer);
      setDuration(data.duration);
      setUseChrono(data.useChrono);
      setUseChronoCaucus(data.useChrono_caucus);
    }
  }, [data]);

  return (
    <Grid>
      <Col md={4}>
        <InputField
          value={id}
          label="Id"
          placeholder="ID"
          type="number"
          isRequired
          handleChange={(e: any) => setId(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <SelectField
          value={type}
          label="Type"
          placeholder="Type"
          choices={THEME_TYPE_LABELS}
          isRequired
          handleChange={(e: any) => setType(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <InputField
          value={theme}
          label="Thème"
          placeholder="Thème"
          type="text"
          isRequired
          handleChange={(e: any) => setTheme(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <InputField
          value={nbPlayer}
          label="Nb joueur(s)"
          placeholder="Nb joueur(s)"
          type="text"
          isRequired
          handleChange={(e: any) => setNbPlayer(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <InputField
          value={category}
          label="Catégorie"
          placeholder="Catégorie"
          type="text"
          isRequired
          handleChange={(e: any) => setCategory(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <DurationInput
          buttonIncrement={15}
          minValue={15}
          maxValue={60 * 60 - 1}
          label="Durée impro"
          value={duration}
          onChange={(value) => setDuration(value)}
        />
      </Col>
      <Col md={4}>
        <SelectField
          value={useChrono}
          label="Chrono"
          placeholder="Chrono"
          choices={THEME_YES_NO}
          isRequired
          handleChange={(e: any) => setUseChrono(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <SelectField
          value={useChrono_caucus}
          label="Chrono caucus"
          placeholder="Chrono caucus"
          choices={THEME_YES_NO}
          isRequired
          handleChange={(e: any) => setUseChronoCaucus(e.target.value)}
        />
      </Col>
      <Col md={4}>
        <Button
          icon="save"
          style={{ margin: "30px auto" }}
          onClick={() => submitForm(themeConfig)}
        >
          Enregistrer
        </Button>
      </Col>
    </Grid>
  );
};

export const CrudTheme = () => {
  const { data, actions } = useLocalstorageProvider();

  const [themes, setThemes] = useState<ThemeType[]>(
    data?.matchData?.themes || []
  );
  const [removedThemes, setRemovedThemes] = useState<ThemeType[]>(
    data?.matchData?.removed_themes || []
  );
  const [editTheme, setEditTheme] = useState<ThemeType>();
  const [showModal, setShowModal] = useState(false);

  const handleAdd = (theme: ThemeType) => {
    const filtered = themes.filter((m) => theme.id !== m.id);
    setThemes([...(filtered || []), theme]);
    setEditTheme(undefined);
    setShowModal(false);
  };

  const handleChangeTheme = (
    value: string | number,
    name: ThemeKey,
    theme: ThemeType,
    index: number
  ) => {
    theme = { ...theme, [name]: value };
    const newThemes = themes
      ?.map((m) => (index !== m.id ? m : theme))
      .sort((a, b) => a.id - b.id);
    setThemes(newThemes);
    setEditTheme(undefined);
    setShowModal(false);
  };

  const handleAddRemovedTheme = (theme: ThemeType | undefined) => {
    if (theme) {
      setRemovedThemes([...(removedThemes || []), theme]);
      setEditTheme(undefined);
      setShowModal(false);
    }
  };

  const handleUpdate = (index: number) => {
    setEditTheme(themes[index]);
    setShowModal(true);
  };

  const handleRemove = (id: number) => {
    if (themes) {
      const filtered = themes.filter((theme) => theme.id !== id);
      setThemes(filtered);
      handleAddRemovedTheme(
        themes.find((theme) => {
          return theme.id === id;
        })
      );
    }
  };

  useEffect(() => {
    const changedData = {
      ...data?.matchData,
      themes: themes,
      removed_themes: removedThemes,
    };

    actions.handleCrudMatchChange(changedData);
  }, [themes, removedThemes]);

  return (
    <Panel>
      Liste des thèmes :{" "}
      <ButtonAdd icon="plus" onClick={() => setShowModal(!showModal)} />
      <hr />
      {themes && <Grid vCenter="center"></Grid>}
      <RowList>
        {themes?.map((theme, index) => (
          <Row key={`row-theme-${index}`}>
            <Col xs={1}>
              <InputField
                value={theme?.id}
                type="number"
                placeholder="ID"
                handleChange={(e: any) => {
                  handleChangeTheme(e.target.value, "id", theme, theme.id);
                }}
              />
            </Col>
            <Shrink>
              <SelectField
                value={theme?.type}
                placeholder="Type"
                choices={THEME_TYPE_LABELS}
                isRequired
                handleChange={(e: any) =>
                  handleChangeTheme(e.target.value, "type", theme, theme.id)
                }
              />
            </Shrink>
            <Auto>
              <InputField
                value={theme?.theme}
                type="texte"
                placeholder="Thème"
                handleChange={(e: any) => {
                  handleChangeTheme(e.target.value, "theme", theme, theme.id);
                }}
              />
            </Auto>
            <Auto>
              <InputField
                value={theme?.category}
                type="texte"
                placeholder="Catégorie"
                handleChange={(e: any) => {
                  handleChangeTheme(
                    e.target.value,
                    "category",
                    theme,
                    theme.id
                  );
                }}
              />
            </Auto>
            <Auto>
              <InputField
                value={theme?.nbPlayer}
                type="texte"
                placeholder="NB. joueur"
                handleChange={(e: any) => {
                  handleChangeTheme(
                    e.target.value,
                    "nbPlayer",
                    theme,
                    theme.id
                  );
                }}
              />
            </Auto>
            <Auto>
              <DurationInput
                buttonIncrement={15}
                minValue={0}
                maxValue={60 * 60 - 1}
                value={theme?.duration}
                onChange={(value) =>
                  handleChangeTheme(value, "duration", theme, theme.id)
                }
              />
            </Auto>
            <Shrink>
              <SelectField
                value={theme?.useChrono}
                choices={THEME_YES_NO}
                isRequired
                placeholder="Chrono"
                handleChange={(e: any) =>
                  handleChangeTheme(
                    e.target.value,
                    "useChrono",
                    theme,
                    theme.id
                  )
                }
              />
            </Shrink>
            <Shrink>
              <SelectField
                value={theme?.useChrono_caucus}
                choices={THEME_YES_NO}
                isRequired
                placeholder="Chrono Caucus"
                handleChange={(e: any) =>
                  handleChangeTheme(
                    e.target.value,
                    "useChrono_caucus",
                    theme,
                    theme.id
                  )
                }
              />
            </Shrink>
            <Shrink>
              <Button icon="save" onClick={() => handleUpdate(index)} />
            </Shrink>
            <Shrink>
              <Button
                icon="close"
                actionType="danger"
                onClick={() => handleRemove(theme?.id)}
              />
            </Shrink>
          </Row>
        ))}
      </RowList>
      {showModal && (
        <Modal closeModal={() => setShowModal(false)} width="800">
          <h2>Ajouter un thème</h2>
          <CrudForm
            data={editTheme || initialImproData.theme}
            nextId={
              themes.length ? Math.max(...themes.map((m) => m.id)) + 1 : 1
            }
            submitForm={handleAdd}
          />
        </Modal>
      )}
    </Panel>
  );
};

export const RoundThemeSelection = () => {
  const { data, actions } = useLocalstorageProvider();
  const { darkMode } = useAppProvider();
  const { improData } = data;
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [addTheme, setAddTheme] = useState<boolean>(false);
  const [removedThemes, setRemovedThemes] = useState<ThemeType[]>(
    data?.matchData?.removed_themes || []
  );

  const [selectedTheme, setSelectedTheme] = useState<ThemeType>();
  const [themes, setThemes] = useState<ThemeType[]>(
    data?.matchData?.themes || []
  );

  useEffect(() => {
    setSelectedTheme(improData?.theme);
  }, [improData]);
  /*
    const handleAdd = (theme: ThemeType, nextId: number) => {
        theme.id = nextId;
        console.log(theme);
        setThemes([...themes, theme]);
        setSelectedTheme(theme);
        setShowEdit(true);
    };


    useEffect(() => {
        const changedData = {
            ...data?.matchData,
            themes: themes,
            removed_themes: removedThemes,
        };
        actions.handleCrudMatchChange(changedData);
    }, [themes]);
*/
  return (
    <>
      {data?.matchData && (
        <p style={{ marginTop: 0 }}>
          <LabelStyled>
            {data?.matchData?.themes?.length > 0
              ? "Choisissez le thème "
              : "Thème de l'impro"}
            :
          </LabelStyled>
          <Grid vCenter="center">
            <Auto>
              {data?.matchData?.themes?.length > 0 ? (
                <SelectStyled
                  defaultValue={JSON.stringify(improData?.theme)}
                  onChange={(e: any) =>
                    actions?.handleImproChange(
                      JSON.parse(e.target.value),
                      "theme"
                    )
                  }
                  darkMode={darkMode}
                >
                  <option>Selectionnez un thème</option>
                  {data?.matchData?.themes?.map(
                    (theme: ThemeType, index: number) => (
                      <option
                        key={`theme-select-${index}`}
                        value={JSON.stringify(theme)}
                      >
                        {theme?.id} - {theme?.type} - {theme?.theme} -{" "}
                        {theme?.nbPlayer} - {theme?.category} -{" "}
                        {durationToString(theme?.duration)} - Chrono:{" "}
                        {theme?.useChrono} - Chrono caucus:{" "}
                        {theme?.useChrono_caucus}
                      </option>
                    )
                  )}
                </SelectStyled>
              ) : (
                <>
                  {data?.improData?.theme?.id} - {data?.improData?.theme?.type}{" "}
                  - {data?.improData?.theme?.theme} -{" "}
                  {data?.improData?.theme?.nbPlayer} -{" "}
                  {data?.improData?.theme?.category} -{" "}
                  {data?.improData?.theme?.duration &&
                    durationToString(data?.improData?.theme?.duration)}
                  - Chrono: {data?.improData?.theme?.useChrono}- Chrono caucus:{" "}
                  {data?.improData?.theme?.useChrono_caucus}
                </>
              )}
            </Auto>
            <Shrink>
              <Button
                icon="options-line"
                onClick={() => setShowEdit(!showEdit)}
              />
              {/*
                    <br/>
                    <Button icon="icon icon-plus"
                            onClick={() => setAddTheme(true)}
                            isOnDark/>
                            */}
            </Shrink>
          </Grid>
        </p>
      )}
      <FormWrapper>
        {selectedTheme && showEdit && (
          <CrudForm
            data={selectedTheme}
            nextId={1}
            submitForm={(theme: ThemeType) =>
              actions?.handleImproChange(theme, "theme")
            }
          />
        )}
        {addTheme && (
          <CrudForm
            data={initialImproData.theme}
            nextId={
              data?.matchData?.themes?.length
                ? Math.max(...data?.matchData?.themes.map((m) => m.id)) + 1
                : 1
            }
            submitForm={(theme: ThemeType) =>
              actions?.handleImproChange(theme, "theme")
            }
          />
        )}
      </FormWrapper>
    </>
  );
};

const Panel = styled.div`
  margin: ${pixToRem(25, 0)};
`;
const RowList = styled.div`
  overflow-y: scroll;
  max-height: 400px;
`;

const ButtonAdd = styled(Button)`
  float: right;
  margin-top: ${pixToRem(-15)};
`;

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  display: flex;
  width: 100%;
`;
const Auto = styled.div`
  flex: 1 1 0;
  padding: ${pixToRem(5)};
`;
const Shrink = styled.div`
  flex: 0 0 auto;
  padding: ${pixToRem(5)};
`;
const FormWrapper = styled.div`
  p {
    margin-top: 0;
  }
`;
