import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { timeFromSeconds, secondsFromTime } from "../helpers/timeFormat";
import { pixToRem } from "../styles/theme.mixins";

type DurationInputType = {
  value: number;
  buttonIncrement: number;
  minValue: number;
  maxValue: number;
  className?: string;
  label?: string;
  style?: any;
  onChange: (value: number) => void;
};

export const DurationInput = (props: DurationInputType) => {
  const [value, setValue] = useState<number>(props.value);
  const [tempValue, setTempValue] = useState<string>(
    timeFromSeconds(props.value)
  );

  const BUTTON_INCREMENT =
    props.buttonIncrement != null ? props.buttonIncrement : 0.1;

  useEffect(() => {
    setValue(props.value);
    setTempValue(timeFromSeconds(props.value));
  }, [props.value]);

  function setSeconds(new_seconds_value: number) {
    if (props.minValue && new_seconds_value < props.minValue) {
      new_seconds_value = props.minValue;
    }

    if (props.maxValue && new_seconds_value > props.maxValue) {
      new_seconds_value = props.maxValue;
    }

    if (new_seconds_value < 0) {
      new_seconds_value = 0;
    }

    props.onChange && props.onChange(new_seconds_value);

    setValue(new_seconds_value);
    setTempValue(timeFromSeconds(new_seconds_value));
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let v = e.target.value;

    if (v.length === 2 && tempValue?.length < v.length) {
      v = e.target.value + ":";
    } else if (v.length === 3 && tempValue?.length < v.length) {
      v = v.slice(0, v.length - 1) + ":" + v.slice(v.length - 1, v.length);
    } else if (v.length === 5 && tempValue?.length < v.length) {
      v = v + ":";
    } else if (v.length === 6 && tempValue?.length < v.length) {
      v = v.slice(0, v.length - 1) + ":" + v.slice(v.length - 1, v.length);
    }

    setTempValue(v);

    const parsed_seconds = secondsFromTime(parseFloat(v));

    if (parsed_seconds != null) {
      props.onChange && props.onChange(parsed_seconds);
    }
  }

  function onBlur(e: any) {
    const parsed_seconds = secondsFromTime(e.target.value);

    if (parsed_seconds == null) {
      setTempValue(timeFromSeconds(value));
    } else {
      setSeconds(parsed_seconds);
    }
  }

  return (
    <p>
      {props.label && <LabelStyled>{props.label} :</LabelStyled>}
      <Wrapper className={props.className} style={props.style}>
        <InputStyled
          className="duration-input"
          type="text"
          pattern="^([0-9]+):([0-9]+):([0-9]+([\.|,][0-9+]*)?)$"
          onChange={handleChange}
          // onKeyDown={handleChange}
          value={tempValue}
          onBlur={onBlur}
        />
        <ButtonsWrapper>
          <IncrementButton
            className="duration-input-button duration-input-up-button"
            onClick={() => setSeconds(value + BUTTON_INCREMENT)}
            style={{ transform: "rotate(-180deg)" }}
          >
            ▾
          </IncrementButton>
          <IncrementButton
            className="duration-input-button duration-input-down-button"
            onClick={() => setSeconds(value - BUTTON_INCREMENT)}
          >
            ▾
          </IncrementButton>
        </ButtonsWrapper>
      </Wrapper>
    </p>
  );
};

const Wrapper = styled.span`
  position: relative;
  display: block;
`;

const LabelStyled = styled.label`
  display: block;
  font-size: ${pixToRem(16)};
  margin-bottom: ${pixToRem(10)};
`;

const InputStyled = styled.input`
  background: ${({ theme }) => theme.colors.bodyTertiary};
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: ${pixToRem(3)};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${pixToRem(16)};
  font-weight: 300;
  padding: ${pixToRem(10, 10)};
  transition: all 250ms ease-out;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand};
    border-style: solid;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.brand};
    outline: 2px solid ${({ theme }) => theme.colors.brand};
  }
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  right: ${pixToRem(8)};
  top: calc(50% + 1px);
  height: calc(100% + 1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 16px;
  transform: translateY(-50%);
`;

const IncrementButton = styled.button`
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: ${pixToRem(18)};
  padding: 0;
  margin: 0;
  height: calc(50% - 2px);
  line-height: 0.8;
`;
