import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Col, Grid } from "../../../styles/components/Grid";
import { LogoTeam } from "../ui/LogoTeam";

export const WelcomeScreen = () => {
  const {
    data: { matchData },
  } = useLocalstorageProvider();

  return (
    <>
      <h1>{matchData?.welcome_title}</h1>
      <Grid vCenter="center">
        <Col md={6} order={matchData?.invert_team_display ? 1 : 0}>
          <LogoTeam teamId="team_left" teamData={matchData?.team_left} />
          <h2>{matchData?.team_left?.name}</h2>
        </Col>
        <Col md={6} order={matchData?.invert_team_display ? 0 : 1}>
          <LogoTeam teamId="team_right" teamData={matchData?.team_right} />
          <h2>{matchData?.team_right?.name}</h2>
        </Col>
      </Grid>
    </>
  );
};
