import styled from "styled-components";
import { useAppProvider } from "../hooks/AppProvider";
import { pixToRem } from "../styles/theme.mixins";

export const SelectField = ({
  label,
  value,
  placeholder,
  choices,
  handleChange,
  isRequired,
}: {
  value: string | number;
  placeholder?: string;
  label?: string;
  choices: string[];
  isRequired?: boolean;
  handleChange: any;
}) => {
  const { darkMode } = useAppProvider();
  return (
    <p>
      {label && <LabelStyled>{label} :</LabelStyled>}
      <SelectStyled
        onChange={handleChange}
        required={isRequired}
        value={value || choices?.[0]}
        onWheel={(e: any) => e.target.blur()}
        darkMode={darkMode}
      >
        {placeholder && (<option disabled={true} value="">{placeholder}</option>)}
        {choices?.map((choice: string, index: number) => (
          <option key={`select-choice-${index}`}>{choice}</option>
        ))}
      </SelectStyled>
    </p>
  );
};

export const LabelStyled = styled.label`
  display: block;
  font-size: ${pixToRem(16)};
  margin-bottom: ${pixToRem(10)};
`;

export const SelectStyled = styled.select<{ darkMode: boolean }>`
  background: ${({ theme }) => theme.colors.bodyTertiary};
  border: 2px solid
    ${({ darkMode }) =>
      darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.3)"};
  border-radius: ${pixToRem(3)};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${pixToRem(16)};
  font-weight: 300;
  padding: ${pixToRem(10, 10)};
  transition: all 250ms ease-out;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand};
    border-style: solid;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.brand};
    outline: 2px solid ${({ theme }) => theme.colors.brand};
  }
`;
