import { TeamType, TeamLabelType } from "../../../type";
import styled from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";
import { Button } from "../../../components/Button";
import { Auto, Grid, Shrink } from "../../../styles/components/Grid";
import { PenalitiesLight } from "./PenalitiesLight";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";

export const TeamAdminContentLight = ({
  data,
  teamId,
  handleChange,
}: {
  data: TeamType | undefined;
  teamId: TeamLabelType;
  handleChange: any;
}) => {
  const { data: { matchData } } = useLocalstorageProvider();
  if (!data) return null;

  return (
    <>
      <Grid>
        <Auto>
          <TeamTitle>{data?.name}</TeamTitle>
        </Auto>
        <Shrink>
          <Score>
            <Button onClick={() => handleChange(data?.score - 1, "score", teamId)} disabled={data?.score === 0}>
              <i className="icon icon-minus" />
            </Button>
            <Points>{data?.score + data?.gift_points}</Points>
            <Button onClick={() => handleChange(data?.score + 1, "score", teamId)}>
              <i className="icon icon-plus" />
            </Button>
          </Score>
        </Shrink>
      </Grid>
      {matchData?.[teamId] && (
        <PenalitiesLight data={matchData[teamId] as TeamType} handleChange={handleChange} teamId={teamId} />
      )}
    </>
  );
};

const TeamTitle = styled.h2`
  margin: 0;
`;

const Score = styled.div`
  align-items: center;
  display: flex;
  font-size: ${pixToRem(26)};
  font-weight: bold;
  justify-content: center;
`;

const Points = styled.div`
  margin: ${pixToRem(0, 15)};
`;
