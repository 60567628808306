import { useEffect, useState } from "react";
import { render } from "react-dom";

import { AppProvider } from "./hooks/AppProvider";
import { LocalstorageProvider } from "./hooks/LocalstorageProvider";
import { Routing } from "./routing";

const App = () => {
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    // const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    // setDarkMode(prefersDarkScheme.matches);
  }, []);

  return (
    <AppProvider darkMode={darkMode} setDarkMode={setDarkMode}>
      <LocalstorageProvider>
        <Routing />
      </LocalstorageProvider>
    </AppProvider>
  );
};

const rootElement = document.getElementById("root");
render(<App />, rootElement);
