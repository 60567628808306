import { useEffect, useState } from "react";
import styled from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";

import { TeamType, TeamLabelType, TeamMemberType } from "../../../type";

import { InputField } from "../../../components/InputField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { SelectField } from "../../../components/SelectField";
import { MEMBER_ROLE_LABELS } from "../../../constants";

const CrudForm = ({
  data,
  nextId,
  submitForm,
}: {
  data: TeamMemberType;
  nextId: number;
  submitForm: any;
}) => {
  const [id, setId] = useState<number>(data?.id);
  const [name, setName] = useState<string>(data?.name);
  const [role, setRole] = useState<string>(data?.role);
  const [member, setMember] = useState<TeamMemberType>();

  useEffect(() => {
    setMember({
      id: id,
      name: name,
      role: role || MEMBER_ROLE_LABELS[0],
    });
  }, [id, name, role]);

  useEffect(() => {
      setId(data.id||nextId);
  }, [data]);

  return (
    <form onSubmit={() => submitForm(member)}>
      <InputField
        value={id}
        label="Id"
        type="text"
        isRequired
        handleChange={(e: any) => setId(e.target.value)}
      />
      <SelectField
          value={role}
          choices={MEMBER_ROLE_LABELS}
          label="Poste"
          handleChange={(e: any) => setRole(e.target.value)}
      />
      <InputField
        value={name}
        label="Nom"
        type="text"
        isRequired
        handleChange={(e: any) => setName(e.target.value)}
      />
      <Button type="submit">Enregistrer</Button>
    </form>
  );
};

export const CrudMember = ({ teamId }: { teamId: TeamLabelType }) => {
  const { data, actions } = useLocalstorageProvider();

  const dataTeam = data?.matchData?.[teamId] as TeamType;

  const [members, setMembers] = useState<TeamMemberType[]>(dataTeam?.members || []);
  const [removedMembers, setRemovedMembers] = useState<TeamMemberType[]>(dataTeam?.removed_members || []);
  const [editMember, setEditMember] = useState<TeamMemberType>();
  const [showModal, setShowModal] = useState(false);

  const handleAdd = (member: TeamMemberType) => {
    const filtered = members.filter((m) => member.id !== m.id);
    setMembers([...(filtered || []), member]);
    setEditMember(undefined);
    setShowModal(false);
  };

  const handleAddRemovedMember = (member: TeamMemberType | undefined) => {
    if (member) {
      setRemovedMembers([...(removedMembers || []), member]);
      setEditMember(undefined);
      setShowModal(false);
    }
  };

  const handleUpdate = (index: number) => {
    setEditMember(members[index]);
    setShowModal(true);
  };

  const handleRemove = (id: number) => {
    if (members) {
      const filtered = members.filter((member) => member.id !== id);
      setMembers(filtered);
      handleAddRemovedMember(members.find(member => { return member.id === id}));
    }
  };

  useEffect(() => {
    const changedData = {
      ...data?.matchData,
      [teamId]: { ...data?.matchData?.[teamId], members: members,
        removed_members: removedMembers}
    };

    actions.handleCrudTeamChange(changedData, teamId);
  }, [members, removedMembers]);

  return (
    <Panel>
      Membres :{" "}
      <ButtonAdd icon="plus" onClick={() => setShowModal(!showModal)} />
      <hr />
      {dataTeam?.members?.map((member, index) => (
        <Row key={`row-member-${index}`}>
          <Shrink>{member?.id}</Shrink>
          <Auto>{member?.role}</Auto>
          <Auto>{member?.name}</Auto>
          <Shrink>
            <Button onClick={() => handleUpdate(index)}>Modifier</Button>
          </Shrink>
          <Shrink>
            <Button
              icon="close"
              actionType="danger"
              onClick={() => handleRemove(member?.id)}
            />
          </Shrink>
        </Row>
      ))}
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <h2>Ajouter un membre</h2>
          <CrudForm
            data={editMember || ({} as TeamMemberType)}
            nextId={dataTeam?.members?.length?Math.max(...dataTeam?.members?.map(m => m.id))+1:1}
            submitForm={handleAdd}
          />
        </Modal>
      )}
    </Panel>
  );
};

const Panel = styled.div`
  margin: ${pixToRem(50, 0)};
`;

const ButtonAdd = styled(Button)`
  float: right;
  margin-top: ${pixToRem(-15)};
`;

const Row = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  display: flex;
  width: 100%;
`;
const Auto = styled.div`
  flex: 1 1 0;
  padding: ${pixToRem(5)};
`;
const Shrink = styled.div`
  flex: 0 0 auto;
  padding: ${pixToRem(5)};
`;
