import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import {
  Auto,
  Grid,
  GridContainer,
  Shrink,
} from "../../../styles/components/Grid";
import { pixToRem } from "../../../styles/theme.mixins";
import { SongType } from "../../../type";

export const MusicPanel = ({ closePanel }: { closePanel: any }) => {
  const [songList, setSongList] = useState<SongType[]>();
  const [active, setActive] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<any>();
  const {
    actions,
    data: { musicData },
  } = useLocalstorageProvider();

  const onDrop = useCallback((acceptedFiles) => {
    let songs: SongType[] = [];
    acceptedFiles.forEach((file: any) => {
      console.log(URL.createObjectURL(file));
      console.log(file);
      const el = {
        name: file.name,
        stream: URL.createObjectURL(file),
      };

      console.log(el);
      songs.push(el);
    });
    setSongList([...(songList || []), ...(songs || [])]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function play(index: number) {
    // setActive(index);
    if (audioRef?.current) {
      audioRef.current.src = songList?.[index].stream;
      audioRef.current.play();
      setActive(index);
      setIsPlaying(true);
    }
  }

  function removeSong(index: number) {
    if (songList) {
      const copy = songList;
      copy.splice(index - 1, index);
      setSongList(copy);
    }
  }

  function listenNext() {
    if (songList?.length === active + 1) {
      play(0);
    } else {
      play(active + 1);
    }
  }

  function pause() {
    audioRef.current.pause();
    setIsPlaying(false);
  }

  useEffect(() => {
    actions.handleMusicChange(songList, "songs");
  }, [songList]);
  useEffect(() => {
    actions.handleMusicChange(active, "active");
  }, [active]);
  useEffect(() => {
    if (audioRef.current) {
      actions.handleMusicChange(isPlaying, "isPlaying");
      if (isPlaying) {
        play(active);
      } else {
        pause();
      }
    }
  }, [isPlaying, audioRef]);
  useEffect(() => {
    if (musicData) {
      setActive(musicData.active);
      setIsPlaying(musicData.isPlaying);
      setSongList(musicData.songs);
    }
  }, []);

  return (
    <PanelWrapper>
      <PanelBackdrop />
      <PanelContent>
        <Title>Fond sonore</Title>
        <GridContainer>
          <DropWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Lâchez les fichiers ici ...</p>
            ) : (
              <p>
                Glissez déposez des fichiers audio ici, ou cliquez pour
                sélectionner des fichiers
              </p>
            )}
          </DropWrapper>
        </GridContainer>
        <GridContainer>
          {songList?.map((song: SongType, index: number) => {
            return (
              <SongItem active={index === active}>
                <Grid vCenter="center">
                  <Auto>{song.name}</Auto>
                  <Shrink>
                    <div>
                      <Button
                        isOnDark={active === index}
                        icon={active === index && isPlaying ? "pause" : "play"}
                        onClick={() =>
                          active === index && isPlaying ? pause() : play(index)
                        }
                      />
                    </div>
                    &nbsp;
                    <div>
                      <Button
                        icon="trash"
                        actionType="danger"
                        onClick={() => removeSong(index)}
                      />
                    </div>
                  </Shrink>
                </Grid>
              </SongItem>
            );
          })}
        </GridContainer>
        {songList && songList?.length > 0 && (
          <audio onEnded={listenNext} id="sound" controls ref={audioRef} />
        )}
        <Buttons>
          <Button icon="save" onClick={() => actions.clearMusic()} actionType="danger">
            Réinitialiser
          </Button>
          &nbsp;
          <Button icon="close" onClick={closePanel}>
            Fermer
          </Button>
        </Buttons>
      </PanelContent>
    </PanelWrapper>
  );
};

const PanelWrapper = styled.div``;

const DropWrapper = styled.div`
  border: 5px dashed ${({ theme }) => theme.colors.brand};
  margin: ${pixToRem(15)};
  padding: ${pixToRem(15)};
  text-align: center;
`;

const Buttons = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.bodySecondary};
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: ${pixToRem(15)};
  position: absolute;
  width: 100%;
`;

const PanelBackdrop = styled.div`
  background: ${({ theme }) => theme.colors.bodySecondary};
  height: 100%;
  position: fixed;
  left: 0;
  opacity: 0.9;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PanelContent = styled.div`
  background: ${({ theme }) => theme.colors.body};
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: ${pixToRem(500)};
  z-index: 101;
`;

const SongItem = styled.div<{ active: boolean }>`
  background-color: ${({ theme, active }) =>
    active ? theme.colors.brand : "transparent"};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${pixToRem(14)};
  padding: ${pixToRem(0, 20)};
`;

const Title = styled.h3`
  text-align: center;
`;
