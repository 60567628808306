import { TeamLabelType } from "../../../type";
import styled from "styled-components";
import { TeamLogo } from "./TeamLogo";
import { InputField } from "../../../components/InputField";
import { CrudMember } from "./CrudMember";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { pixToRem } from "../../../styles/theme.mixins";
import {ColorField} from "../../../components/ColorField";
import {CheckboxField} from "../../../components/CheckboxField";

export const TeamAdminEdit = ({ teamId }: { teamId: TeamLabelType }) => {
  const { data, actions } = useLocalstorageProvider();

  return (
    <>
      <TeamTitle>
        <TeamLogo teamId={teamId} />
        <h2>{teamId === "team_left" ? "Équipe A" : "Équipe B"}</h2>
      </TeamTitle>
      <InputField
        value={data?.matchData?.[teamId]?.name || ""}
        label="Nom de l'équipe"
        type="text"
        handleChange={(e: any) => {
          actions.handleTeamChange(e.target.value, "name", teamId);
          actions.handleImproTeamChange(e.target.value, "name", teamId);
        }}
      />
        <CheckboxField
            checked={data?.matchData?.[teamId]?.use_vote_color||false}
            label="Utiliser couleur vote"
            id="use_vote_color"
            isRequired
            handleChange={(e: any) => {
                actions.handleTeamChange(e.target.checked, "use_vote_color", teamId);
                actions.handleImproTeamChange(e.target.checked, "use_vote_color", teamId);
            }}
        />
        <ColorField
            value={data?.matchData?.[teamId]?.vote_color || "#ffffff"}
            handleChange={(e: any) => {
                actions.handleTeamChange(e.hex, "vote_color", teamId);
                actions.handleImproTeamChange(e.hex, "vote_color", teamId);
            }}
        />
      <CrudMember teamId={teamId} />
    </>
  );
};

const TeamTitle = styled.div`
  align-items: center;
  display: flex;
  padding-top: ${pixToRem(20)};

  h2 {
    margin-left: ${pixToRem(10)};
  }
`;
