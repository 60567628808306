import deepmerge from "deepmerge";
import {
  useContext,
  ReactElement,
  createContext,
  useEffect,
  useState,
} from "react";

import {
  matchKey,
  roundKey,
  historyKey,
  screenKey,
  PERIOD_DURATION_DEFAULT,
  PAUSE_DURATION_DEFAULT,
  TRAINING_DURATION_DEFAULT,
  CAUCUS_DURATION_DEFAULT,
  IMPRO_DURATION_DEFAULT,
  THEME_TYPE_LABELS,
  THEME_YES_NO,
  musicKey,
} from "../constants";

import {
  MatchKey,
  MatchType,
  ImproKey,
  TeamKey,
  ImproType,
  TeamLabelType,
  ScreenType,
  ScreenKey,
  TeamType,
  ScreenName,
  ScreenThemeKey,
  ApiDataType,
  MusicType,
} from "../type";
import { useAppProvider } from "./AppProvider";

type LocalstorageContextType = {
  data: {
    matchData: MatchType | undefined;
    improData: ImproType | undefined;
    screenData: ScreenType | undefined;
    historyData: ImproType[];
    musicData: MusicType | undefined;
  };
  actions: {
    handleMatchChange: any;
    handleMatchApiChange: any;
    handleCrudMatchChange: any;
    handleImproChange: any;
    handleScreenChange: (value: any, name: ScreenKey) => void;
    handleScreenApiChange: any;
    handleThemeChange: any;
    handleTeamChange: Function;
    handleCrudTeamChange: Function;
    handleImproTeamChange: Function;
    handleNextImpro: Function;
    handleNextPeriod: Function;
    handleMusicChange: (value: any, name: keyof MusicType) => string;
    setMatchData: any;
    showScreen: (screen: ScreenName, autoplay?: boolean) => void;
    clearAll: any;
    clearHistory: any;
    clearTheme: any;
    clearMusic: any;
    initDataFromAPI: (apiData: ApiDataType) => void;
  };
};

const initialMatchData = {
  duration: PERIOD_DURATION_DEFAULT,
  nb_periods: 2,
  pause_duration: PAUSE_DURATION_DEFAULT,
  training_duration: TRAINING_DURATION_DEFAULT,
  caucus_duration: CAUCUS_DURATION_DEFAULT,
  timer: 0,
  themes: [],
  removed_themes: [],
  current_period: 1,
  welcome_title: "Bienvenue",
  training_title: "Échauffement",
  training_duration_override: TRAINING_DURATION_DEFAULT,
  invert_team_admin: false,
  invert_team_display: false,
  hymn_title: "Hymnes",
  team_left: {
    name: "Équipe A",
    score: 0,
    gift_points: 0,
    use_vote_color: false,
    vote_color: "#ffffff",
  } as TeamType,
  team_right: {
    name: "Équipe B",
    score: 0,
    gift_points: 0,
    use_vote_color: false,
    vote_color: "#ffffff",
  } as TeamType,
};

export const initialImproData = {
  theme: {
    id: 0,
    theme: '',
    category: 'Libre',
    nbPlayer: "Illimité",
    type: THEME_TYPE_LABELS[0],
    duration: IMPRO_DURATION_DEFAULT,
    useChrono: THEME_YES_NO[1],
    useChrono_caucus: THEME_YES_NO[0],
  },
  round_number: 1,
  team_left: {
    name: "Équipe A",
    score: 0,
    gift_points: 0,
    vote_color: "#ffffff",
  } as TeamType,
  team_right: {
    name: "Équipe B",
    score: 0,
    gift_points: 0,
    vote_color: "#ffffff",
  } as TeamType,
  period: 1,
  vote_title: "Votre vote",
  caucus_title: "Caucus",
  caucus_useChrono: THEME_YES_NO[0],
  caucus_useChrono_impro: THEME_YES_NO[0],
} as ImproType;

const initialScreenData = {
  show: "",
  auto_play: false,
  hymn_active: "",
  listen_vote_mic: false,
  presence_team_left: [],
  presence_team_right: [],
  period_title: "Fin de période\r\nReprise dans :",
  end_match_title: "Fin du match\r\nÀ bientôt",
  theme: {
    color_main: "#00d9ff",
    color_background: "#0f1620",
    color_foreground: "#ffffff",
    use_digit: true,
    show_audio_vizualizer: false,
    vote_with_audio: false,
    use_layout: "2",
    resize_screen: false,
    margin_top: 0,
    margin_bottom: 36,
    margin_left: 0,
    margin_right: 0,
    resize_screen_color: "#ffffff",
    max_width: 100,
    default_width: true,
    font_size: 100,
    chrono_dot_top: 40,
    chrono_dot_down: 160,
  },
} as ScreenType;

export const initialMusicData = {
  active: 0,
  isPlaying: false,
  songs: [],
  screens: {},
} as MusicType;

export const LocalstorageContext = createContext<LocalstorageContextType>(
  {} as LocalstorageContextType
);

export function LocalstorageProvider({ children }: { children: ReactElement }) {
  const { screenPopup, actions: appActions } = useAppProvider();
  const [matchData, setMatchData] = useState<MatchType>();
  const [improData, setImproData] = useState<ImproType>();
  const [screenData, setScreenData] = useState<ScreenType>();
  const [musicData, setMusicData] = useState<MusicType>();
  const [historyData, setHistoryData] = useState<ImproType[]>([]);

  const initDataFromAPI = (apiData: ApiDataType) => {
    if (apiData) {
      const cloneScreenData = JSON.parse(JSON.stringify(screenData)); // clone
      const apiScreenData = {
        ...screenData,
        period_title: findPhaseByType(apiData?.phases, "PERIODEND") || cloneScreenData?.period_title,
        end_match_title:findPhaseByType(apiData?.phases, "END") || cloneScreenData?.end_match_title,
        theme: {
          color_main: apiData?.mainColor || cloneScreenData?.theme?.color_main,
          color_background: apiData?.backColor || cloneScreenData?.theme?.color_background,
          color_foreground: apiData?.fontColor || cloneScreenData?.theme?.color_foreground,
          use_layout: apiData?.globalTheme || cloneScreenData?.theme?.use_layout,
          use_digit: apiData?.squareDigit || cloneScreenData?.theme?.use_digit,
        }
      };

      const cloneMatchData = JSON.parse(JSON.stringify(matchData)); // clone
      const themes = apiData?.rounds.map((apiTheme: {
        position: number;
        title: string;
        categoryString: string;
        type: string;
        playerNumber: string;
        time: number;
        useChrono: boolean; }) => {
        return {
          id: apiTheme.position,
          theme: apiTheme.title,
          category: apiTheme.categoryString,
          type: apiTheme.type,
          nbPlayer: apiTheme.playerNumber,
          duration: (apiTheme.time * 60) > 0 ?? IMPRO_DURATION_DEFAULT,
          useChrono: apiData.roundUseChrono ? 'Oui' : 'Non'
        }
      });

      themes.forEach((theme: any) => {
        handleImproApiChange(theme);
      });

      const apiTeamLeftIndex = findApiTeamIndexByPosition(apiData?.teams, 1);
      const apiTeamRightIndex = findApiTeamIndexByPosition(apiData?.teams, 2);
      const apiMatchData = {
        ...matchData,
        duration : apiData?.periodTime || cloneMatchData?.duration,
        caucus_duration: apiData?.caucusDuration || cloneMatchData?.caucus_duration,
        pause_duration: apiData?.pauseDuration || cloneMatchData?.pause_duration,
        training_duration: apiData?.trainingDuration || cloneMatchData?.training_duration,
        training_duration_override: apiData?.trainingDuration || cloneMatchData?.training_duration_override,
        nb_periods: apiData?.periodNumber || cloneMatchData?.nb_periods,
        themes: themes,
        removed_themes: [],
        hymn_title: apiData?.hymnTitle || cloneMatchData?.hymn_title,
        training_title: findPhaseByType(apiData?.phases, "WARMUP") || cloneMatchData?.training_title,
        vote_title: apiData?.voteTitle || cloneMatchData?.vote_title,
        welcome_title: findPhaseByType(apiData?.phases, "WELCOME") || cloneMatchData?.welcome_title,
        team_left: {
          name: apiTeamLeftIndex != null ? apiData?.teams[apiTeamLeftIndex]?.name : cloneMatchData?.team_left.name,
          members: apiTeamLeftIndex != null ? getApiTeamMembersDataByTeamIndex(apiData?.teams[apiTeamLeftIndex]?.teamMembers) : [],
          removed_members: [],
        },
        team_right: {
          name: apiTeamRightIndex != null ? apiData?.teams[apiTeamRightIndex]?.name : cloneMatchData?.team_right.name,
          members: apiTeamRightIndex != null ? getApiTeamMembersDataByTeamIndex(apiData?.teams[apiTeamRightIndex]?.teamMembers) : [],
          removed_members: [],
        }
      };

      handleScreenApiChange(apiScreenData);
      handleMatchApiChange(apiMatchData);
    }
  };

  function findPhaseByType(apiPhases: any[], apiPhaseType: string) {
    let formattedPhase;

    apiPhases.map((phase: {
      type: string;
      content: string;
    }) => {

      if (apiPhaseType === phase.type) {
        if (phase.content) {
          formattedPhase = phase.content;
        }
      }
    });

    return formattedPhase ?? '';
  }

  function findApiTeamIndexByPosition(apiDataTeams: any[], position: number)
  {
    for (let i = 0; i < apiDataTeams.length; i++) {
        if (apiDataTeams[i].position == position) {
          return i;
        }
    }

    return null;
  }

  function getApiTeamMembersDataByTeamIndex(apiDataTeamMembers: any[])
  {
    return apiDataTeamMembers.map((apiTeamMember: {
      name: string;
      position: number;
      type: string;
    }) => {
      return {
        id: apiTeamMember.position,
        name: apiTeamMember.name,
        role: apiTeamMember.type,
      }
    });
  }

  const handleMatchChange = (value: any, name: MatchKey) => {
    const data = { ...(matchData || {}), [name]: value };
    setMatchData(data as MatchType);

    return value;
  };

  const handleCrudMatchChange = (value: any) => {
    const data = { ...(matchData || {}), ...value};
    setMatchData(data as MatchType);

    return value;
  };

  const handleScreenApiChange = (value: any) => {
    const data = { ...(screenData || {}), ...value};
    setScreenData(data as ScreenType);

    return value;
  };

  const handleMatchApiChange = (value: any) => {
    const data = { ...(matchData || {}), ...value};
    setMatchData(data as MatchType);

    return value;
  };

  const handleImproApiChange = (value: any) => {
    const data = { ...(improData || {}), ...value};
    setImproData(data as ImproType);

    return value;
  };

  const handleScreenChange = (value: any, name: ScreenKey) => {
    const data = { ...(screenData || {}), [name]: value };
    setScreenData(data as ScreenType);

    return value;
  };

  const handleThemeChange = (value: string | boolean, name: ScreenThemeKey) => {
    const clone = JSON.parse(JSON.stringify(screenData)); // clone
    const data = { ...clone?.theme, [name]: value };
    handleScreenChange(data, "theme");

    return value;
  };

  const handleImproChange = (value: any, name: ImproKey) => {
    const data = deepmerge(improData || {}, { [name]: value });
    setImproData(data as ImproType);

    return value;
  };

  const handleTeamChange = (
    value: string,
    name: TeamKey,
    teamId: TeamLabelType
  ) => {
    const match = {
      ...matchData,
      [teamId]: { ...matchData?.[teamId], [name]: value },
    };
    setMatchData(match as MatchType);
    return value;
  };

  const handleCrudTeamChange = (value: any, teamId: TeamLabelType) => {
    const data = { ...(matchData || {}), ...value};
    setMatchData(data as MatchType);

    return value;
  };

  const handleImproTeamChange = (
    value: string,
    name: TeamKey,
    teamId: TeamLabelType
  ) => {
    const impro = {
      ...improData,
      [teamId]: { ...improData?.[teamId], [name]: value },
    };
    setImproData(impro as ImproType);

    return value;
  };

  const handleMusicChange = (value: string, name: keyof MusicType) => {
    const data = { ...(musicData || {}), [name]: value };
    setMusicData(data as MusicType);

    return value;
  };

  const handleNextImpro = (isNewPeriod?: boolean) => {
    if (improData && matchData) {
      // Archive
      const data = [...historyData, improData];
      setHistoryData(data);

      // Update match score with round points + gift points
      const updateMatch = {
        ...matchData,
        current_period: isNewPeriod
          ? matchData?.current_period + 1
          : matchData?.current_period,
        team_left: {
          ...matchData?.team_left,
          score:
            (matchData.team_left?.score || 0) + improData?.team_left?.score,
          gift_points:
            (matchData.team_left?.gift_points || 0) +
            improData?.team_left?.gift_points,
        },
        team_right: {
          ...matchData?.team_right,
          score:
            (matchData.team_right?.score || 0) + improData?.team_right?.score,
          gift_points:
            (matchData.team_right?.gift_points || 0) +
            improData?.team_right?.gift_points,
        },
      };
      setMatchData(updateMatch as MatchType);

      // Reset data for next round but keep scores to increase
      const resetRound = {
        ...improData,
        theme: initialImproData.theme,
        period: isNewPeriod
          ? matchData?.current_period + 1
          : matchData?.current_period,
        round_number: data?.length + 1,
        team_left: {
          ...improData?.team_left,
          penalities: [],
          gift_points: 0,
        },
        team_right: {
          ...improData?.team_right,
          penalities: [],
          gift_points: 0,
        },
      };
      setImproData(resetRound as ImproType);
    }
  };

  const handleNextPeriod = () => {
    if (improData && matchData) {
      handleNextImpro(true);
    }
  };

  const showScreen = (screen: ScreenName, autoplay?: boolean) => {
    if (screenData) {
      screenData.auto_play = autoplay ?? false;
    }
    handleScreenChange(screen, "show");
    if (!screenPopup || screenPopup.closed) {
      appActions.openScorePopup();
    }
  };

  const readLocalStorage = () => {
    const match = localStorage.getItem(matchKey);
    const round = localStorage.getItem(roundKey);
    const history = localStorage.getItem(historyKey);
    const screen = localStorage.getItem(screenKey);
    const music = localStorage.getItem(musicKey);

    setMatchData(match ? JSON.parse(match) : initialMatchData);
    setImproData(round ? JSON.parse(round) : initialImproData);
    setScreenData(screen ? JSON.parse(screen) : initialScreenData);
    setMusicData(music ? JSON.parse(music) : initialMusicData);

    if (history) setHistoryData(JSON.parse(history));
  };

  const clearHistory = () => {
    setHistoryData([]);
  };

  const clearTheme = () => {
    localStorage.setItem('color_main', initialScreenData.theme.color_main);
    localStorage.setItem('color_background', initialScreenData.theme.color_background);
    localStorage.setItem('color_foreground', initialScreenData.theme.color_foreground);
    const data = { ...screenData, theme: initialScreenData.theme };
    setScreenData(data as ScreenType);
  };

  const clearMusic = () => {
    setMusicData(initialMusicData);
  };

  const clearAll = () => {
    setMatchData(initialMatchData);
    setImproData(initialImproData);
    setScreenData(initialScreenData);
    setMusicData(initialMusicData);
    setHistoryData([]);
  };

  useEffect(() => {
    // Retrieve all data from local storage
    readLocalStorage();

    window.addEventListener("storage", readLocalStorage);
    return () => {
      window.removeEventListener("storage", readLocalStorage);
    };
  }, []);

  useEffect(() => {
    if (matchData) {
      localStorage.setItem(matchKey, JSON.stringify(matchData));
    }
  }, [matchData]);

  useEffect(() => {
    if (improData) {
      localStorage.setItem(roundKey, JSON.stringify(improData));
    }
  }, [improData]);

  useEffect(() => {
    if (screenData) {
      localStorage.setItem(screenKey, JSON.stringify(screenData));
    }
  }, [screenData]);

  useEffect(() => {
    if (musicData) {
      localStorage.setItem(musicKey, JSON.stringify(musicData));
    }
  }, [musicData]);

  useEffect(() => {
    if (historyData) {
      localStorage.setItem(historyKey, JSON.stringify(historyData));
    }
  }, [historyData]);

  const value = {
    data: {
      matchData,
      improData,
      screenData,
      historyData,
      musicData,
    },
    actions: {
      handleMatchChange,
      handleMatchApiChange,
      handleCrudMatchChange,
      handleImproChange,
      handleScreenChange,
      handleScreenApiChange,
      handleThemeChange,
      handleTeamChange,
      handleCrudTeamChange,
      handleImproTeamChange,
      handleMusicChange,
      handleNextImpro,
      handleNextPeriod,
      showScreen,
      setMatchData,
      clearAll,
      clearHistory,
      clearMusic,
      clearTheme,
      initDataFromAPI,
    },
  };

  return (
    <LocalstorageContext.Provider value={value}>
      {children}
    </LocalstorageContext.Provider>
  );
}

export const useLocalstorageProvider = () => {
  return useContext(LocalstorageContext);
};
