import { useEffect, useState } from "react";
import { CAUCUS_DURATION_DEFAULT, IMPRO_DURATION_DEFAULT, PAUSE_DURATION_DEFAULT, TRAINING_DURATION_DEFAULT } from "../constants";
import { useLocalstorageProvider } from "./LocalstorageProvider";

export const useChrono = (LSKey: "timer_round" | "timer_match" | "timer_training" | "timer_caucus" | "timer_pause_period") => {
  const [secondLeft, setSecondLeft] = useState<number>();
  const [play, setPlay] = useState(false);
  const [loop, setLoop] = useState<any>();
  const [duration, setDuration] = useState<any>();

  const {
    data: { matchData, improData, screenData },
  } = useLocalstorageProvider();

  const decreaseTimer = () => {
    const current = parseInt(localStorage.getItem(LSKey) || "0");
    if (current > 0) {
      const sec = current - 1;
      setSecondLeft(sec);
    } else {
      setPlay(false);
      setSecondLeft(0);
    }
  };

  const resetTimer = (initSec?: number) => {
    setSecondLeft(initSec || duration);
    setPlay(false);
    clearInterval(loop);
  };

  const autoplayTimer = () => {
    setPlay(true);
  };

  const mutateTimer = () => {
    const chrono = localStorage.getItem(LSKey);
    setSecondLeft(parseInt(chrono || "0"));
  };

  useEffect(() => {
    if (play) {
      setLoop(setInterval(decreaseTimer, 1000));
    } else {
      clearInterval(loop);
    }
  }, [play]);

  useEffect(() => {
    let seconds = 0;

    switch (LSKey) {
      case "timer_caucus":
        seconds = improData?.caucus_duration || matchData?.caucus_duration || CAUCUS_DURATION_DEFAULT;
        break;
      case "timer_match":
        seconds = matchData?.duration || 0;
        break;
      case "timer_round":
        seconds = improData?.theme?.duration || IMPRO_DURATION_DEFAULT;
        break;
      case "timer_pause_period":
        seconds = screenData?.period_pause_duration || matchData?.pause_duration || PAUSE_DURATION_DEFAULT;
        break;
      case "timer_training":
        seconds = matchData?.training_duration_override || matchData?.training_duration || TRAINING_DURATION_DEFAULT;
        break;
    }

    if (seconds !== duration || secondLeft === 0) {
      resetTimer(seconds);
    } else {
      const lsTime = localStorage.getItem(LSKey);
      if (!secondLeft) {
        setSecondLeft(lsTime ? parseInt(lsTime) : seconds);
      }
    }

    setDuration(seconds);
  }, [matchData, improData, screenData, LSKey]);

  useEffect(() => {
    if (secondLeft !== undefined) localStorage.setItem(LSKey, secondLeft.toString());
  }, [secondLeft, LSKey]);

  const handleStorageUpdate = () => {
    mutateTimer();
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageUpdate);
    return () => {
      window.removeEventListener("storage", handleStorageUpdate);
    };
  }, [LSKey]);

  return {
    secondLeft,
    play,
    actions: {
      setPlay,
      resetTimer,
      autoplayTimer,
      mutateTimer,
    },
  };
};
