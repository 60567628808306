import { useEffect, useState } from "react";
import styled from "styled-components";
import { durationToString } from "../helpers/timeFormat";
import { useLocalstorageProvider } from "../hooks/LocalstorageProvider";
import { useChrono } from "../hooks/useChrono";
import { pixToRem } from "../styles/theme.mixins";
import { Button } from "./Button";

type TimerType = "round" | "match" | "training" | "caucus" | "pause_period";

export const ChronoControl = ({
  label,
  type,
  style,
  autoplay,
  font_size,
}: {
  label: string;
  type: "round" | "match" | "training" | "caucus" | "pause_period";
  style?: any;
  autoplay?: boolean;
  font_size?: number;
}) => {
  const [currentType, setCurrentType] = useState<TimerType>("round");
  const {
    data: { improData },
  } = useLocalstorageProvider();
  const [activateAutoplay, setActivateAutoplay] = useState<boolean>(false);

  // Reset timer if main chrono change from caucus to impro timer or any other "type"
  useEffect(() => {
    actions.resetTimer();
    setCurrentType(type);
    if (autoplay) {
      setActivateAutoplay(true);
    }
  }, [type]);

  // Reset round timer only if new impro
  useEffect(() => {
    if (type === "round") {
      actions.resetTimer();
    }
  }, [improData?.round_number]);

  const { secondLeft, play, actions } = useChrono(`timer_${currentType}`);

  useEffect(() => {
    if (activateAutoplay) {
      actions.autoplayTimer();
      setActivateAutoplay(false);
    }
  }, [activateAutoplay]);

  return (
    type && (
      <Panel secondLeft={secondLeft || 0} style={style}>
        <Chrono font_size={font_size ?? 22}>
          <i className="icon icon-chrono" />
          &nbsp;
          {label}
          &nbsp;
          <strong>{durationToString(secondLeft || 0)}</strong>
        </Chrono>
        {play ? (
          <Button icon="pause" onClick={() => actions.setPlay(false)}>
            Pause
          </Button>
        ) : (
          <Button icon="play" onClick={() => actions.setPlay(true)}>
            Start
          </Button>
        )}
        &nbsp;
        <Button icon="reset" onClick={() => actions.resetTimer()}>
          Reinit.
        </Button>
      </Panel>
    )
  );
};

const Panel = styled.div<{ secondLeft: number }>`
  align-items: center;
  border-radius: ${pixToRem(15)};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  margin-bottom: ${pixToRem(15)};
  padding: ${pixToRem(10)};
  transition: all 250ms ease-out;

  ${({ secondLeft, theme }) =>
    secondLeft === 0
      ? `
      background: ${theme.colors.danger};
      border: 3px solid ${theme.colors.danger};
      `
      : secondLeft < 30
      ? `
      background: ${theme.colors.warning};
      border: 3px solid ${theme.colors.warning};
      `
      : `
      background: ${theme.colors.bodySecondary};
      border: 3px solid ${theme.colors.bodySecondary};
  `}

  & button {
    font-size: ${pixToRem(14)};
    font-weight: 300;
    padding: ${pixToRem(5, 10)};
  }
`;

const Chrono = styled.div<{ font_size: number }>`
  align-items: center;
  display: flex;
  flex: 1 1 0;
  // font-size: ${({ font_size }) => pixToRem(font_size)};
`;
