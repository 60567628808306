/* eslint-disable no-unused-vars */
import React, { ReactNode } from "react";
import styled from "styled-components";

import {
  contentWidth,
  largeBreakpoint,
  mediumBreakpoint,
  smallBreakpoint,
} from "../theme.config";

import { pixToRem } from "../theme.mixins";

type GridContainerType = {
  children?: ReactNode[] | ReactNode;
  noGutter?: boolean;
  style?: any;
};

type GridType = {
  children?: ReactNode[] | ReactNode;
  center?: string;
  style?: any;
  vCenter?: string;
  noGutter?: boolean;
};

type ColType = {
  children: ReactNode[] | ReactNode;
  noGutter?: boolean;
  order?: number;
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

type BreakpointType = {
  width: number;
  gutter: number;
};

export const GridContainer = ({
  children,
  noGutter = false,
  ...props
}: GridContainerType) => {
  return (
    <GridWrapper noGutter={noGutter} {...props}>
      {children}
    </GridWrapper>
  );
};

export const Grid = ({
  children,
  center,
  vCenter,
  noGutter,
  ...props
}: GridType) => {
  return (
    <GridRow center={center} vCenter={vCenter} noGutter={noGutter} {...props}>
      {children}
    </GridRow>
  );
};

export const Col = ({
  children,
  xs,
  md,
  lg,
  xl,
  order,
  noGutter,
  ...props
}: ColType) => {
  return (
    <GridItem
      xs={xs}
      md={md}
      lg={lg}
      xl={xl}
      order={order}
      noGutter={noGutter}
      {...props}
    >
      {children}
    </GridItem>
  );
};

const getRowMarginMQ = (breakpoint: BreakpointType, noGutter?: boolean) => {
  return `
    @media (min-width: ${pixToRem(breakpoint.width)}) {
      margin-left: -${noGutter ? 0 : pixToRem(breakpoint.gutter / 2)};
      margin-right: -${noGutter ? 0 : pixToRem(breakpoint.gutter / 2)};
    }
  `;
};

const getColWidth = (
  breakpoint: BreakpointType,
  size: number,
  noGutter?: boolean
) => {
  return (
    size &&
    `width: calc(${(size * 100) / 12}%${noGutter ? "" : ` - ${pixToRem(breakpoint.gutter)}`
    });`
  );
};

const getColProperties = (
  breakpoint: BreakpointType,
  size: number,
  noGutter?: boolean
) => {
  return `
    ${size ? getColWidth(breakpoint, size, noGutter) : ""}
    margin-left: ${noGutter ? 0 : pixToRem(breakpoint.gutter / 2)};
    margin-right: ${noGutter ? 0 : pixToRem(breakpoint.gutter / 2)};
  `;
};

const getColWidthMQ = (
  breakpoint: BreakpointType,
  size: number,
  noGutter?: boolean
) => {
  return `
    @media (min-width: ${pixToRem(breakpoint.width)}) {
      ${getColProperties(breakpoint, size, noGutter)}
    }

    @media print {
      ${getColProperties(breakpoint, size, noGutter)}
    }`;
};

const GridWrapper = styled.div<GridContainerType>`
  display: block;
  margin: 0 auto;
  max-width: ${pixToRem(contentWidth - largeBreakpoint.gutter)};

  @media screen and (max-width: ${pixToRem(contentWidth)}) {
    margin-left: ${(props) =>
    props.noGutter ? "auto" : pixToRem(smallBreakpoint.gutter / 2)};
    margin-right: ${(props) =>
    props.noGutter ? "auto" : pixToRem(smallBreakpoint.gutter / 2)};
  }

  @media print {
    max-width: ${pixToRem(largeBreakpoint.width)};
    width: ${pixToRem(largeBreakpoint.width)};
  }
`;

const GridRow = styled.div<GridType>`
  align-items: ${(props) => props.vCenter};
  justify-content: ${(props) => props.center};
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  padding: 0;

  ${(props) =>
    getRowMarginMQ(
      { width: 0, gutter: smallBreakpoint.gutter },
      props.noGutter
    )};
  ${(props) => getRowMarginMQ(smallBreakpoint, props.noGutter)}
  ${(props) => getRowMarginMQ(mediumBreakpoint, props.noGutter)}
  ${(props) => getRowMarginMQ(largeBreakpoint, props.noGutter)}
`;

const GridItem = styled.div<ColType>`
  flex: 0 0 auto;
  order: ${(props) => props.order || "inherit"};
  width: ${(props) =>
    props.noGutter
      ? "100%"
      : `calc(100% - ${pixToRem(smallBreakpoint.gutter)})`};

  ${(props) =>
    getColWidthMQ(
      { width: 0, gutter: smallBreakpoint.gutter },
      props.xs || 12,
      props.noGutter
    )};
  ${(props) =>
    getColWidthMQ(smallBreakpoint, props.md || props.xs || 12, props.noGutter)};
  ${(props) =>
    getColWidthMQ(
      mediumBreakpoint,
      props.lg || props.md || props.xs || 12,
      props.noGutter
    )};
  ${(props) =>
    getColWidthMQ(
      largeBreakpoint,
      props.xl || props.lg || props.md || props.xs || 12,
      props.noGutter
    )};
`;

export const Auto = styled.div<{ noGutter?: boolean }>`
  flex: 1 1 0;
  padding: ${({ noGutter }) => pixToRem(noGutter ? 0 : 5)};
`;

export const Shrink = styled.div`
  display: flex;
  flex: 0 0 auto;
  padding: ${pixToRem(5)};
`;
