import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppProvider } from "../hooks/AppProvider";
import { pixToRem } from "../styles/theme.mixins";

export const CheckboxField = ({
  label,
  checked,
  handleChange,
  isRequired,
  id,
}: {
  checked: boolean;
  label?: string;
  isRequired?: boolean;
  handleChange: any;
  id: string;
}) => {
  const { darkMode } = useAppProvider();
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, []);

  useEffect(() => {
    handleChange({ target: { checked: isChecked } });
  }, [isChecked]);

  return (
    <>
      <InputStyled
        value="on"
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        required={isRequired}
        id={id}
        type="checkbox"
        darkMode={darkMode}
      />
      {label && <LabelStyled htmlFor={id}>{label}</LabelStyled>}
    </>
  );
};

const LabelStyled = styled.label<{ htmlFor: string }>`
  font-size: ${pixToRem(16)};
`;

const InputStyled = styled.input<{ darkMode: boolean }>`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${pixToRem(16)};
  font-weight: 300;
  line-height: 0;
  margin-right: ${pixToRem(10)};
  padding: ${pixToRem(10, 10)};
  transition: all 250ms ease-out;
  vertical-align: middle;

  &:hover {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand};
    border-style: solid;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.brand};
    outline: 2px solid ${({ theme }) => theme.colors.brand};
  }
`;
