import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Button } from "../../../components/Button";
import { CheckboxField } from "../../../components/CheckboxField";
import { ColorField } from "../../../components/ColorField";
import { SelectField } from "../../../components/SelectField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Col, Grid } from "../../../styles/components/Grid";
import { pixToRem } from "../../../styles/theme.mixins";
import { ScreenThemeType } from "../../../type";
import { InputField } from "../../../components/InputField";

export const ThemePanel = ({ closePanel }: { closePanel: any }) => {
  const { data, actions } = useLocalstorageProvider();
  const [themeData, setThemeData] = useState<ScreenThemeType>();
  const { custom } = useTheme();

  useEffect(() => {
    console.log(data);
    if (data?.screenData?.theme) {
      setThemeData(data?.screenData?.theme);
    }
  }, []);

  return themeData ? (
    <PanelWrapper>
      <PanelBackdrop />
      <PanelContent>
        <ThemeForm>
          <Title>Apparence</Title>
          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={6}>Couleur principale</Col>
            <Col xs={6}>
              <ColorField
                value={
                  localStorage?.color_main?.length > 0
                    ? localStorage.color_main
                    : custom.color_main
                }
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.hex, "color_main")
                }
              />
            </Col>
          </Grid>
          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={6}>Couleur de fond</Col>
            <Col xs={6}>
              <ColorField
                value={
                  localStorage?.color_background?.length > 0
                    ? localStorage.color_background
                    : custom.color_background
                }
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.hex, "color_background")
                }
              />
            </Col>
          </Grid>
          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={6}>Couleur de texte</Col>
            <Col xs={6}>
              <ColorField
                value={
                  localStorage?.color_foreground?.length > 0
                    ? localStorage.color_foreground
                    : custom.color_foreground
                }
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.hex, "color_foreground")
                }
              />
            </Col>
          </Grid>

          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={6}>Utiliser le thème</Col>
            <Col xs={6}>
              <SelectWrapperStyled>
                <SelectField
                  value={custom.use_layout}
                  choices={["1", "2", "3"]}
                  isRequired
                  handleChange={(e: any) =>
                    actions.handleThemeChange(e.target.value, "use_layout")
                  }
                />
              </SelectWrapperStyled>
            </Col>
            <Col xs={6}>Taille de police</Col>
            <Col xs={6}>
              <InputField
                value={custom.font_size}
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "font_size")
                }
              />
            </Col>
          </Grid>
          <CheckboxField
            checked={custom.resize_screen}
            label="Appliquer des marges"
            id="resize_screen"
            isRequired
            handleChange={(e: any) =>
              actions.handleThemeChange(e.target.checked, "resize_screen")
            }
          />
          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={3}>
              <InputField
                value={custom.margin_top ?? 0}
                label="Haut"
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "margin_top")
                }
              />
            </Col>
            <Col xs={3}>
              <InputField
                value={custom.margin_bottom ?? 0}
                label="Bas"
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "margin_bottom")
                }
              />
            </Col>
            <Col xs={3}>
              <InputField
                value={custom.margin_left ?? 0}
                label="Gauche"
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "margin_left")
                }
              />
            </Col>
            <Col xs={3}>
              <InputField
                value={custom.margin_right ?? 0}
                label="Droite"
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "margin_right")
                }
              />
            </Col>
            <Col xs={6}>Couleur de redimentionnement</Col>
            <Col xs={6}>
              <ColorField
                value={custom.resize_screen_color}
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.hex, "resize_screen_color")
                }
              />
            </Col>
          </Grid>
          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={6}>
              <CheckboxField
                checked={custom.default_width}
                label="Modifier largeur"
                id="default_width"
                isRequired
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.checked, "default_width")
                }
              />
            </Col>
            <Col xs={3}>Largeur interieur</Col>
            <Col xs={3}>
              <InputField
                value={custom.max_width}
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "max_width")
                }
              />
            </Col>
          </Grid>
          <Grid vCenter="center" style={{ marginBottom: pixToRem(10) }}>
            <Col xs={6}>
              <InputField
                value={custom.chrono_dot_top ?? 0}
                label="Chiffre point haut"
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "chrono_dot_top")
                }
              />
            </Col>
            <Col xs={6}>
              <InputField
                value={custom.chrono_dot_down ?? 0}
                label="Chiffre point bas"
                type="number"
                handleChange={(e: any) =>
                  actions.handleThemeChange(e.target.value, "chrono_dot_down")
                }
              />
            </Col>
          </Grid>
          <p>
            <CheckboxField
              checked={custom.use_digit}
              label="Afficher les chiffres digitaux"
              id="use_digit"
              isRequired
              handleChange={(e: any) =>
                actions.handleThemeChange(e.target.checked, "use_digit")
              }
            />
          </p>
          {/*
          <p>
            <CheckboxField
              checked={custom.vote_with_audio}
              label="Utiliser le micro pour voter"
              id="vote_with_audio"
              isRequired
              handleChange={(e: any) =>
                actions.handleThemeChange(e.target.checked, "vote_with_audio")
              }
            />
          </p>
          <CheckboxField
            checked={custom.show_audio_vizualizer}
            label="Afficher l'audio vizualizer en fond"
            id="show_audio_vizualizer"
            isRequired
            handleChange={(e: any) =>
              actions.handleThemeChange(e.target.checked, "show_audio_vizualizer")
            }
          />
          */}
        </ThemeForm>
        <Buttons>
          <Button
            icon="save"
            onClick={() => actions.clearTheme()}
            actionType="danger"
          >
            Réinitialiser
          </Button>
          &nbsp;
          <Button icon="close" onClick={closePanel}>
            Fermer
          </Button>
        </Buttons>
      </PanelContent>
    </PanelWrapper>
  ) : null;
};

const PanelWrapper = styled.div``;

const ThemeForm = styled.div`
  height: calc(100% - 65px);
  overflow: auto;
  padding: ${pixToRem(15)};
`;

const Buttons = styled.div`
  background: ${({ theme }) => theme.colors.body};
  border-top: 1px solid ${({ theme }) => theme.colors.bodySecondary};
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: ${pixToRem(15)};
  position: absolute;
  width: 100%;
`;

const PanelBackdrop = styled.div`
  background: ${({ theme }) => theme.colors.bodySecondary};
  height: 100%;
  position: fixed;
  left: 0;
  opacity: 0.9;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PanelContent = styled.div`
  background: ${({ theme }) => theme.colors.body};
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: ${pixToRem(500)};
  z-index: 101;
`;

const Title = styled.h1`
  flex: 1 1 0;
`;

const SelectWrapperStyled = styled.div`
  p {
    margin: 0 !important;
  }
`;
