import styled from "styled-components";
import { useAppProvider } from "../hooks/AppProvider";
import { pixToRem } from "../styles/theme.mixins";

export const TextareaField = ({
  label,
  value,
  handleChange,
  isRequired,
}: {
  value: string;
  label?: string;
  isRequired?: boolean;
  handleChange: any;
}) => {
  const { darkMode } = useAppProvider();
  return (
    <p>
      {label && <LabelStyled>{label} :</LabelStyled>}
      <TextareaStyled
        onChange={handleChange}
        required={isRequired}
        onWheel={(e: any) => e.target.blur()}
        darkMode={darkMode}
        defaultValue={value}
      />
    </p>
  );
};

export const LabelStyled = styled.label`
  display: block;
  font-size: ${pixToRem(16)};
  margin-bottom: ${pixToRem(10)};
`;

export const TextareaStyled = styled.textarea<{ darkMode: boolean }>`
  background: ${({ theme }) => theme.colors.bodyTertiary};
  border: 2px solid
    ${({ darkMode }) =>
      darkMode ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.3)"};
  border-radius: ${pixToRem(3)};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${pixToRem(16)};
  font-weight: 300;
  padding: ${pixToRem(10, 10)};
  transition: all 250ms ease-out;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand};
    border-style: solid;
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.brand};
    outline: 2px solid ${({ theme }) => theme.colors.brand};
  }
`;
