import {useEffect, useState,} from "react";

import axios, {AxiosResponse} from 'axios';
import {ApiDataType} from "../type";
import {useLocalstorageProvider} from "./LocalstorageProvider";

type ApiContext = {
  apiData: ApiDataType | undefined;
  error: any;
  actions: {
    setApiData: any;
  };
};

type PostContext = {
  responseMessage: any;
  error: any;
}

export const useFetchApi = ({ eventId, token }: { eventId?: string, token?: string }): ApiContext => {
  const [apiData, setApiData] = useState<ApiDataType>();
  const [error, setError] = useState();

  const fetchAction = async () => {
    if (eventId && token) {
      await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/historic/event/admin-model?id=${eventId}&token=${token}`)
        .then((response: AxiosResponse<ApiDataType>) => {
          setApiData(response.data);
        })
        .catch(error => {
          setError(error);
        });
    }
  };

  useEffect(() => {
    fetchAction();
  }, [eventId, token]);

  return {
    apiData,
    error,
    actions: {
      setApiData
    }
  }
};

export const usePostApi = ({ eventId, token, livescoreData }: { eventId?: string, token?: string, livescoreData?: any }): PostContext => {
  const { data, actions } = useLocalstorageProvider();
  const [responseMessage, setResponseMessage] = useState();
  const [error, setError] = useState();
  const formattedData = getFormattedHistoricData(livescoreData);

  const postAction = async () => {
    if (eventId && token) {
      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/historic/event/save?id=${eventId}&token=${token}`, formattedData)
          .then(response => setResponseMessage(response.data.message))
          .catch(error => {
            setError(error);
            console.error('There was an error!', error);
          });
    }
  }

  useEffect(() => {
    postAction();

    // To get refreshed data after removing objects in database, I clear it
    const changedData = {
      ...data?.matchData,
      team_left : { ...data?.matchData?.team_left, removed_members: [] },
      team_right : { ...data?.matchData?.team_right, removed_members: [] },
      removed_themes: [],
    };

    actions.handleCrudMatchChange(changedData);
  }, [formattedData]);

  return {
    responseMessage,
    error
  };
};

function getFormattedHistoricData(livescoreData: any)
{
  if (livescoreData.matchData) {

    return JSON.stringify({
      backColor: livescoreData.screenData.theme.color_background,
      caucusDuration: livescoreData.matchData.caucus_duration,
      fontColor: livescoreData.screenData.theme.color_foreground,
      globalTheme: livescoreData.screenData.theme.use_layout,
      hymnTitle: livescoreData.matchData.hymn_title,
      mainColor: livescoreData.screenData.theme.color_main,
      pauseDuration: livescoreData.matchData.pause_duration,
      periodNumber: livescoreData.matchData.nb_periods,
      periodTime: livescoreData.matchData.duration,
      squareDigit: livescoreData.screenData.theme.use_digit,
      trainingDuration: livescoreData.matchData.training_duration,
      voteTitle: livescoreData.matchData.vote_title,
      teams: getFormattedTeams(livescoreData.matchData, null),
      removedRounds: getFormattedThemesData(livescoreData.matchData.removed_themes),
      rounds: [...getFormattedThemesData(livescoreData.matchData.themes), ...getFormattedPlayedRounds(livescoreData)],
      phases: getFormattedPhases(livescoreData),
    });
  }

  return JSON.stringify({});
}

function getFormattedPhases(livescore: any)
{
  const formattedPhases = [];

  formattedPhases.push({
    type: "WELCOME",
    content: livescore.matchData.welcome_title,
    time: null,
  });

  formattedPhases.push({
    type: "WARMUP",
    content: livescore.matchData.training_title,
    time: livescore.matchData.training_duration,
  });

  formattedPhases.push({
    type: "PERIODEND",
    content: livescore.screenData.period_title,
    time: livescore.screenData.pause_duration,
  });

  formattedPhases.push({
    type: "END",
    content: livescore.screenData.end_match_title,
    time: null,
  });

  return formattedPhases;
}

function getFormattedPlayedRounds(livescore: any)
{
  const formattedPlayedRounds: {
      period: any; caucusTitle: any; caucusUseChrono: any; playedPosition: any; voteTitle: any; played: boolean; categoryString: any; position: any;
      title: any; type: any; playerNumber: any; time: any; useChrono: any; teams: { name: any; members: { position: any; name: any; type: any; }[]; }[];
  }[] = [];

  livescore.historyData?.forEach((round: { period: any; caucus_title: any; caucus_useChrono: any; round_number: any; vote_title: any; theme: { category: any; id: any; theme: any; type: any; nbPlayer: any; duration: any; useChrono: any; }; }) => {
    formattedPlayedRounds.push({
      period: round.period,
      caucusTitle : round.caucus_title,
      caucusUseChrono : round.caucus_useChrono,
      playedPosition: round.round_number,
      voteTitle: round.vote_title,
      played: true,
      categoryString: round.theme.category,
      position: round.theme.id,
      title: round.theme.theme,
      type: round.theme.type,
      playerNumber: round.theme.nbPlayer,
      time: round.theme.duration,
      useChrono: round.theme.useChrono,
      teams: getFormattedTeams(livescore.matchData, round),
    });
  });

  return formattedPlayedRounds;
}

function getFormattedTeams(matchData: any, round: any)
{
  // if we need the roundData with the right score we replace it in matchData
  // because in round the name of teams is the basic "Equipe A"...
  if (round) {
    matchData.team_left.score = round.team_left.score;
    matchData.team_right.score = round.team_right.score;
  }

  const teamsData = [matchData.team_left, matchData.team_right];
  let formattedTeams: { name: any; removedMembers: { position: any; name: any; type: any; }[]; members: { position: any; name: any; type: any; }[]; faults: { type: any; }[]; score: any; }[] = [];

  teamsData.forEach((teamData) => {

    formattedTeams.push({
      name: teamData.name ?? null,
      removedMembers: teamData.removed_members ? getFormattedMembers(teamData.removed_members) : [],
      members: teamData.members ? getFormattedMembers(teamData.members) : [],
      faults: teamData.penalities ? getFormattedFaults(teamData.penalities) : [],
      score: teamData.score ?? null,
    });
  });

  return formattedTeams;
}

function getFormattedFaults(penaltiesData: any)
{
  let formattedPenalties: { type: any; }[] = [];

  penaltiesData.forEach((penaltyData: any) => {
    formattedPenalties.push({
      type: penaltyData,
    })
  });

  return formattedPenalties;
}

function getFormattedMembers(membersData: any)
{
  let formattedMembers: { position: any; name: any; type: any; }[] = [];

  membersData.forEach((memberData: { id: any; name: any; role: any; }) => {
    formattedMembers.push({
      position: memberData.id,
      name: memberData.name,
      type: memberData.role,
    })
  });

  return formattedMembers;
}

function getFormattedThemesData(themesData: any)
{
  let themes: { position: any; title: any; categoryString: any; type: any; playerNumber: any; time: any; useChrono: any; }[] = [];

  themesData.forEach((themeData: { id: any; theme: any; category: any; type: any; nbPlayer: any; duration: any; useChrono: any; }) => {
    themes.push({
        position: themeData.id,
        title: themeData.theme,
        categoryString: themeData.category,
        type: themeData.type,
        playerNumber: themeData.nbPlayer,
        time: themeData.duration,
        useChrono: themeData.useChrono,
      });
    });

  return themes;
}