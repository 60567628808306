import { createGlobalStyle } from "styled-components";
import { pixToRem } from "./theme.mixins";

// GLOBAL STYLE

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }


  body {
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: "poppins", sans-serif;
    font-size: ${pixToRem(18)};
    font-weight: 300;
    font-style: normal;
  }

  a,
  button {
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    text-decoration: none;
    transition: color 300ms ease-out;

    &:hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  input, textarea, button {font-family: inherit}

  body .tooltip {
    border-radius: 45px;
    font-size: ${pixToRem(14)};
    font-weight: 500;
    text-transform: uppercase;
    padding: ${pixToRem(6, 15, 3)};
  }

  hr {
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.text};
  }
`;
