import { Button } from "../../../components/Button";
import { TextareaField } from "../../../components/TextareaField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";

export const FormEndMatch = ({ hideScreenButtons }: { hideScreenButtons?: boolean }) => {
  const { data, actions } = useLocalstorageProvider();

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Fin de match</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button onClick={() => actions.showScreen("screen_empty")}>Écran vide</Button>&nbsp;
            <Button onClick={() => actions.showScreen("screen_end_match")} icon="screen" />
          </Shrink>
        )}
      </Grid>
      <TextareaField
        label="Message à l'écran"
        value={data?.screenData?.end_match_title || ""}
        handleChange={(e: any) => actions.handleScreenChange(e.target.value, "end_match_title")}
      />
    </>
  );
};
