import React, {
  useContext,
  ReactElement,
  createContext,
  useEffect,
  useState,
} from "react";

type AppContextType = {
  darkMode: any;
  screenPopup: any;
  urlParams: any;
  error: any;
  actions: {
    setDarkMode: any;
    setUrlParams: any;
    openScorePopup: any;
  };
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export function AppProvider({
  children,
  darkMode,
  setDarkMode,
}: {
  children: ReactElement;
  darkMode: boolean;
  setDarkMode: any;
}) {
  const [screenPopup, setScreenPopup] = useState<any>();
  const [urlParams, setUrlParams] = useState<any>();

  function openScorePopup() {
    const popup = window.open("/livescore", "Live score", "popup");
    setScreenPopup(popup);
  }

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode ? "1" : "0");
  }, [darkMode]);

  const value = {
    darkMode,
    urlParams,
    screenPopup,
    error: false,
    actions: {
      setDarkMode,
      setUrlParams,
      openScorePopup,
    },
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useAppProvider = () => {
  return useContext(AppContext);
};
