import { Button } from "../../../components/Button";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";
import { PresenceCheck } from "../ui/PresenceCheck";

export const FormPresenceCheck = () => {
  const { data, actions } = useLocalstorageProvider();

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Appel des joueurs</SectionTitle>
        </Auto>
        <Shrink>
          <Button icon="screen" onClick={() => actions.showScreen("screen_presence_team_a")}>
            Afficher {data?.matchData?.team_left?.name}
          </Button>
          &nbsp;
          <Button icon="screen" onClick={() => actions.showScreen("screen_presence_team_b")}>
            Afficher {data?.matchData?.team_right?.name}
          </Button>
        </Shrink>
      </Grid>
      <Grid>
        <Col md={6}>
          <PresenceCheck teamId="team_left" />
        </Col>
        <Col md={6}>
          <PresenceCheck teamId="team_right" />
        </Col>
      </Grid>
    </>
  );
};
