import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { Button } from "../../../components/Button";
import { ChronoControl } from "../../../components/ChronoControl";
import { Modal } from "../../../components/Modal";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { useClickOutside } from "../../../hooks/useClickOutside";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";
import { FormCaucus } from "../forms/FormCaucus";
import { FormEndMatch } from "../forms/FormEndMatch";
import { FormEndPeriod } from "../forms/FormEndPeriod";
import { FormHymn } from "../forms/FormHymn";
import { FormImpro } from "../forms/FormImpro";
import { FormPresenceCheck } from "../forms/FormPresenceCheck";
import { FormTeams } from "../forms/FormTeams";
import { FormTraining } from "../forms/FormTraining";
import { FormVote } from "../forms/FormVote";
import { FormWelcome } from "../forms/FormWelcome";
import { SectionWrapper, SectionWrapperFocus } from "../styles/SectionStyles";
import { RoundThemeSelection } from "../ui/CrudTheme";
import { TeamAdminContentLight } from "../ui/TeamAdminContentLight";
import { useChrono } from "../../../hooks/useChrono";
import { pixToRem } from "../../../styles/theme.mixins";

type OptionsPanelType =
  | "welcome"
  | "training"
  | "check_presence"
  | "teams"
  | "hymn"
  | "match"
  | "caucus"
  | "vote"
  | "end_period"
  | "end_match"
  | "all";
type TimerType = "round" | "training" | "caucus" | "pause_period";

export const MatchPanelV2 = () => {
  const theme = useTheme();
  const [currentTimer, setCurrentTimer] = useState<TimerType>("round");
  const [timerLabel, setTimerLabel] = useState<string>("Fin du round :");
  const [hideTimer, setHideTimer] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<OptionsPanelType>();

  const optionsScreen = useClickOutside(() => setShowOptions(undefined));
  const { data, actions } = useLocalstorageProvider();

  const { improData, matchData, screenData } = data;
  useEffect(() => {
    setHideTimer(false);
    if (screenData?.show === "screen_training") {
      setCurrentTimer("training");
      setTimerLabel("Fin échauffe :");
    } else if (screenData?.show === "screen_caucus") {
      setCurrentTimer("caucus");
      setTimerLabel("Fin caucus :");
    } else if (screenData?.show === "screen_impro") {
      setCurrentTimer("round");
      setTimerLabel("Fin impro :");
    } else if (screenData?.show === "screen_end_period") {
      setCurrentTimer("pause_period");
      setTimerLabel("Reprise dans :");
    } else {
      // default
      setCurrentTimer("round");
      setTimerLabel("Fin du round :");
      setHideTimer(true);
    }
  }, [screenData?.show]);

  return (
    <>
      <MainAdminScreen>
        <SectionTitle>L'avant match</SectionTitle>
        <SectionWrapper style={{paddingTop: pixToRem(40)}}>
          <OptionPanel ref={optionsScreen}>
            <Grid vCenter="center" center="center">
              <Auto style={{ display: "flex", padding: pixToRem(0, 10) }}>
                <Button
                  actionType="tertiary"
                  onClick={() => actions.showScreen("screen_welcome")}
                  isOnDark={screenData?.show === "screen_welcome"}
                  style={{ flex: "1 1 0" }}
                >
                  Écran d'accueil
                </Button>
                &nbsp;
                <Button
                  actionType="tertiary"
                  icon="option"
                  onClick={() =>
                    setShowOptions(
                      showOptions === "welcome" ? undefined : "welcome"
                    )
                  }
                />
              </Auto>
              <Auto style={{ display: "flex", padding: pixToRem(0, 10) }}>
                <Button
                  actionType="tertiary"
                  onClick={() => actions.showScreen("screen_training", false)}
                  isOnDark={screenData?.show === "screen_training"}
                  style={{ flex: "1 1 0" }}
                >
                  Échauffement
                </Button>
                &nbsp;
                <Button
                  actionType="tertiary"
                  icon="chrono"
                  onClick={() => actions.showScreen("screen_training", true)}
                  isOnDark={screenData?.show === "screen_training"}
                />
                &nbsp;
                <Button
                  actionType="tertiary"
                  icon="option"
                  onClick={() =>
                    setShowOptions(
                      showOptions === "training" ? undefined : "training"
                    )
                  }
                />
              </Auto>
              <Auto style={{ display: "flex", padding: pixToRem(0, 10) }}>
                <Button
                  actionType="tertiary"
                  onClick={() => setShowOptions("check_presence")}
                  isOnDark={
                    screenData?.show === "screen_presence_team_a" ||
                    screenData?.show === "screen_presence_team_b"
                  }
                  style={{ flex: "1 1 0" }}
                >
                  Call des joueurs
                </Button>
                &nbsp;
                <Button
                  actionType="tertiary"
                  icon="option"
                  onClick={() =>
                    setShowOptions(
                      showOptions === "teams" ? undefined : "teams"
                    )
                  }
                />
              </Auto>
              <Auto style={{ display: "flex", padding: pixToRem(0, 10) }}>
                <Button
                  actionType="tertiary"
                  onClick={() =>
                    setShowOptions(showOptions === "hymn" ? undefined : "hymn")
                  }
                  isOnDark={
                    screenData?.show === "screen_hymn_team_a" ||
                    screenData?.show === "screen_hymn_team_b"
                  }
                  style={{ flex: "1 1 0" }}
                >
                  Hymne
                </Button>
              </Auto>
              <Auto style={{ display: "flex", padding: pixToRem(0, 10) }}>
                <Button
                  actionType="tertiary"
                  onClick={() => actions.showScreen("screen_empty")}
                  isOnDark={screenData?.show === "screen_impro"}
                  style={{ flex: "1 1 0" }}
                >
                  Match
                </Button>
                &nbsp;
                <Button
                  actionType="tertiary"
                  icon="option"
                  onClick={() =>
                    setShowOptions(
                      showOptions === "match" ? undefined : "match"
                    )
                  }
                />
              </Auto>
            </Grid>

            {showOptions && (
              <Modal closeModal={() => setShowOptions(undefined)} width={1200}>
                {showOptions === "welcome" && <FormWelcome hideScreenButtons />}
                {showOptions === "training" && (
                  <FormTraining hideScreenButtons />
                )}
                {showOptions === "teams" && <FormTeams />}
                {showOptions === "check_presence" && <FormPresenceCheck />}
                {showOptions === "hymn" && <FormHymn />}
                {showOptions === "match" && <FormImpro />}
                {showOptions === "caucus" && <FormCaucus hideScreenButtons />}
                {showOptions === "vote" && <FormVote hideScreenButtons />}
                {showOptions === "end_period" && (
                  <FormEndPeriod hideScreenButtons />
                )}
                {showOptions === "end_match" && (
                  <FormEndMatch hideScreenButtons />
                )}
                <Button onClick={() => setShowOptions(undefined)}>
                  Fermer
                </Button>
              </Modal>
            )}
          </OptionPanel>
        </SectionWrapper>
      </MainAdminScreen>
      {/*<ChronoControl label="Fin de la période :" type="match" />*/}
      <MainAdminScreen>
        <SectionTitle style={{backgroundColor: theme.colors.bodyTertiary}}>Le match</SectionTitle>
        <SectionWrapperFocus>
          <Grid vCenter="center">
            <Col xs={2}>
              <Grid>
                <Auto>
                  <Button
                    actionType="secondary"
                    onClick={() => {
                      actions.showScreen("screen_caucus", false);
                    }}
                    isActive={screenData?.show === "screen_caucus"}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Caucus
                  </Button>
                </Auto>
                <Shrink>
                  <Button
                    actionType="secondary"
                    icon="option"
                    onClick={() =>
                      setShowOptions(
                        showOptions === "caucus" ? undefined : "caucus"
                      )
                    }
                  />
                </Shrink>
                <Shrink>
                  <Button
                    actionType="secondary"
                    icon="chrono"
                    onClick={() => {
                      actions.showScreen("screen_caucus", true);
                    }}
                    isActive={screenData?.show === "screen_caucus"}
                  />
                </Shrink>
              </Grid>
              <Grid>
                <Auto>
                  <Button
                    actionType="secondary"
                    isActive={screenData?.show === "screen_impro"}
                    onClick={() => actions.showScreen("screen_impro", false)}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Impro
                  </Button>
                </Auto>
                <Shrink>
                  <Button
                    icon="chrono"
                    onClick={() => {
                      actions.showScreen("screen_impro", true);
                    }}
                    actionType="secondary"
                    isActive={screenData?.show === "screen_impro"}
                  />
                </Shrink>
              </Grid>
              <Grid>
                <Auto>
                  <Button
                    onClick={() => actions.showScreen("screen_vote")}
                    actionType="secondary"
                    isActive={screenData?.show === "screen_vote"}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Vote
                  </Button>
                </Auto>
                <Shrink>
                  <Button
                    actionType="secondary"
                    icon="option"
                    onClick={() =>
                      setShowOptions(
                        showOptions === "vote" ? undefined : "vote"
                      )
                    }
                  />
                </Shrink>
              </Grid>
              <Grid>
                <Auto>
                  <Button
                    actionType="secondary"
                    onClick={() => {
                      actions.handleNextImpro();
                      actions.showScreen("screen_empty");
                    }}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Nouvelle impro
                  </Button>
                </Auto>
              </Grid>
            </Col>
            <Auto style={{ padding: pixToRem(0, 20) }}>
              <RoundTitle>
                Pér. {matchData?.current_period} - Impro{" "}
                {improData?.round_number} -{" "}
                {screenData?.show
                  ?.replaceAll("_", " ")
                  .replaceAll("screen", "")}
              </RoundTitle>
              <br />
              <ChronoControl
                type={currentTimer}
                label={timerLabel}
                style={{ margin: 0, opacity: hideTimer ? 0.2 : 1 }}
                autoplay={screenData?.auto_play}
              />
              <ChronoControlSecond>
                <ChronoControl
                  label="Fin de la période :"
                  type="match"
                  style={{ margin: 0 }}
                  font_size={16}
                />
              </ChronoControlSecond>
            </Auto>
            <Col xs={2}>
              <Grid>
                <Auto>
                  <Button
                    actionType="secondary"
                    isActive={screenData?.show === "screen_empty"}
                    onClick={() => actions.showScreen("screen_empty")}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Écran vide
                  </Button>
                </Auto>
              </Grid>
              <Grid>
                <Auto>
                  <Button
                    onClick={() => actions.showScreen("screen_end_period")}
                    actionType="secondary"
                    isActive={screenData?.show === "screen_end_period"}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Fin de période
                  </Button>
                </Auto>
                <Shrink>
                  <Button
                    actionType="secondary"
                    icon="option"
                    onClick={() =>
                      setShowOptions(
                        showOptions === "end_period" ? undefined : "end_period"
                      )
                    }
                  />
                </Shrink>
              </Grid>
              <Grid>
                <Auto>
                  <Button
                    actionType="secondary"
                    onClick={() => {
                      actions.handleNextPeriod();
                      actions.showScreen("screen_empty");
                    }}
                    isActive={screenData?.show === "screen_empty"}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Nouvelle période
                  </Button>
                </Auto>
              </Grid>
              <Grid>
                <Auto>
                  <Button
                    onClick={() => actions.showScreen("screen_end_match")}
                    actionType="secondary"
                    isActive={screenData?.show === "screen_end_match"}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    Fin match
                  </Button>
                </Auto>
                <Shrink>
                  <Button
                    actionType="secondary"
                    icon="option"
                    onClick={() =>
                      setShowOptions(
                        showOptions === "end_match" ? undefined : "end_match"
                      )
                    }
                  />
                </Shrink>
              </Grid>
            </Col>
          </Grid>
        </SectionWrapperFocus>
        {data?.matchData && (
          <SectionWrapper>
            <RoundThemeSelection />
          </SectionWrapper>
        )}
        <SectionWrapper>
          <Grid vCenter="center">
            <Col md={5} order={matchData?.invert_team_admin ? 2 : 0}>
              <TeamAdminContentLight
                data={improData?.team_left}
                teamId="team_left"
                handleChange={actions.handleImproTeamChange}
              />
            </Col>
            <Col md={2} order={1}>
              <Button
                onClick={() =>
                  actions.handleMatchChange(
                    !matchData?.invert_team_admin,
                    "invert_team_admin"
                  )
                }
              >
                Inverser les équipes - admin
              </Button>
              &nbsp;
              <Button
                onClick={() =>
                  actions.handleMatchChange(
                    !matchData?.invert_team_display,
                    "invert_team_display"
                  )
                }
              >
                Inverser les équipes - display
              </Button>
            </Col>
            <Col md={5} order={matchData?.invert_team_admin ? 0 : 2}>
              <TeamAdminContentLight
                data={improData?.team_right}
                teamId="team_right"
                handleChange={actions.handleImproTeamChange}
              />
            </Col>
          </Grid>
        </SectionWrapper>
      </MainAdminScreen>
    </>
  );
};

const RoundTitle = styled.h3`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

const OptionPanel = styled.div``;
const SectionTitle = styled.div`
  background: ${({ theme }) => theme.colors.bodySecondary};
  border-radius: ${pixToRem(10)};
  box-shadow: 0 0 15px #333;
  display: inline-block;
  font-size: ${pixToRem(18)};
  font-weight: bold;
  padding: ${pixToRem(10, 40)};
  position: absolute;
  text-transform: uppercase;
  left: ${pixToRem(30)};
  top: ${pixToRem(-30)};
`;

const MainAdminScreen = styled.div`
  background: ${({ theme }) => theme.colors.bodySecondary};
  border-radius: ${pixToRem(15)};
  margin-bottom: ${pixToRem(30)};
  margin-top: ${pixToRem(20)};
  position: relative;
  
  &:nth-child(2) {
    margin-bottom: ${pixToRem(50)};
  }
`;

const ChronoControlSecond = styled.div`
  margin: ${pixToRem(10, 0)};
`;
