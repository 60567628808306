import { useEffect, useState } from "react";
import styled from "styled-components";
import { useChrono } from "../../../../hooks/useChrono";
import { useAppProvider } from "../../../../hooks/AppProvider";
import { useLocalstorageProvider } from "../../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid } from "../../../../styles/components/Grid";
import { colorShade, percentPixToRemList } from "../../../../styles/theme.mixins";
import { TeamScoreContent } from "../../ui/TeamScoreContent";
import { Timer } from "../../ui/Timer";
import { LogoTeam } from "../../ui/LogoTeam";
import {ScoreScreenType, ScreenThemeType} from "../../../../type";
import { AudioVote } from "../../../../components/AudioVote";
import {durationToString} from "../../../../helpers/timeFormat";

function ScoreScreenTheme2({ impro, empty, caucus, vote, endPeriod, endMatch, title }: ScoreScreenType) {
  const [hideCenterCol, setHideCenterCol] = useState<boolean>();

  const {
    data: { matchData, improData, screenData },
  } = useLocalstorageProvider();

  const { actions } = useAppProvider();
  const { secondLeft: chronoMatch } = useChrono("timer_match");
  const { secondLeft: chronoRound } = useChrono("timer_round");
  const { secondLeft: chronoCaucus } = useChrono("timer_caucus");
  const { secondLeft: chronoPeriod } = useChrono("timer_pause_period");

  const handleStorageUpdate = () => {
    const darkMode = localStorage.getItem("darkMode");
    actions.setDarkMode(darkMode);
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageUpdate);
    return () => {
      window.removeEventListener("storage", handleStorageUpdate);
    };
  }, []);

  useEffect(() => {
    setHideCenterCol(empty || (vote && !screenData?.theme.vote_with_audio));
  }, [empty, vote, screenData?.theme.vote_with_audio]);

  return (
    <>
      <Grid vCenter={!endMatch && !impro ? "center" : "flex-end"}>
        <Col md={hideCenterCol ? 5 : 3} order={matchData?.invert_team_display ? 2 : 0}>
          <LogoTeam teamId="team_left" teamData={improData?.team_left} />
          {matchData?.team_left && improData?.team_left && (
            <TeamScoreContent
              name={matchData.team_left.name}
              team={improData.team_left}
              penalities={matchData.team_left.penalities}
              score={1 * improData.team_left?.score + matchData?.team_left?.gift_points}
              hidePenalities={caucus || endPeriod || endMatch}
            />
          )}
        </Col>
        <Col md={hideCenterCol ? 2 : 6} order={1}>
          {!empty && !endMatch && !(vote && screenData?.theme.vote_with_audio) ? (
            <>
              {(caucus ||impro) && improData?.theme?.theme && (
                <RoundOptions>
                  <Option noBorder>
                    <LabelOption>THÈME</LabelOption>
                    <ValueOptionTheme>{improData?.theme?.theme || "-"}</ValueOptionTheme>
                  </Option>
                </RoundOptions>
              )}

              <RoundOptions>
                {title && !impro && <Round noBorder>{title}</Round>}
                {!vote && !endMatch
                  && !(impro && improData?.theme?.useChrono === "Non")
                  && !(caucus && improData?.caucus_useChrono === "Non")? (
                  <Timer timeLeft={(caucus ? chronoCaucus : endPeriod ? chronoPeriod : chronoRound) || 0} size={3} />
                ) : (
                  ""
                )}

                {vote && screenData?.theme.vote_with_audio && (
                    <>
                      <Vote>{improData?.vote_title || "--"}</Vote>
                      {screenData?.theme.vote_with_audio && (<AudioVote hideActions />)}
                    </>
                )}
              </RoundOptions>
              {caucus && ((chronoRound && improData?.theme?.useChrono_caucus === "Oui" && improData?.caucus_useChrono_impro === "Oui") || improData?.theme?.category) && (
                  <RoundOptions>
                    <Option noBorder>
                      {(chronoRound && improData?.theme?.useChrono_caucus === "Oui" && improData?.caucus_useChrono_impro === "Oui") && (
                          <LabelOption noBorder>
                            DURÉE: {durationToString(chronoRound || 0)}
                          </LabelOption>
                      )}
                      {improData?.theme?.category && (chronoRound && improData?.theme?.useChrono_caucus === "Oui" && improData?.caucus_useChrono_impro === "Oui") && (
                        <LabelOption noBorder borderTop>
                          CATÉGORIE: {improData?.theme?.category || "Libre"}
                        </LabelOption>
                      )}
                      {improData?.theme?.category && !(chronoRound && improData?.theme?.useChrono_caucus === "Oui" && improData?.caucus_useChrono_impro === "Oui") && (
                        <LabelOption noBorder>
                          CATÉGORIE: {improData?.theme?.category || "Libre"}
                        </LabelOption>
                      )}
                    </Option>
                  </RoundOptions>
              )}
              {impro && improData?.theme?.category && (
                  <RoundOptions>
                    <Option noBorder>
                      <LabelOption>CATÉGORIE</LabelOption>
                      <ValueOption>{improData?.theme?.category || "Libre"}</ValueOption>
                    </Option>
                  </RoundOptions>
              )}
            </>
          ) : endMatch ? (
            <EndMatchMessage>{title && <Round noBorder>{title}</Round>}</EndMatchMessage>
          ) : (
            ""
          )}
        </Col>
        <Col md={hideCenterCol ? 5 : 3} order={matchData?.invert_team_display ? 0 : 2}>
          <LogoTeam teamId="team_right" teamData={improData?.team_right} />
          {matchData?.team_right && improData?.team_right && (
            <TeamScoreContent
              name={matchData.team_right.name}
              team={improData.team_right}
              penalities={matchData.team_right.penalities}
              score={1 * improData.team_right?.score + matchData?.team_right?.gift_points}
              hidePenalities={caucus || endPeriod || endMatch}
            />
          )}
        </Col>
      </Grid>
      {!endPeriod && !endMatch ? (
          <RoundOptions>
            <Grid noGutter>
              {(caucus ||impro) && improData?.theme?.type && (
                  <Auto noGutter>
                    <Option noBorder borderRight>
                    <LabelOption noBorder>Type: {improData?.theme?.type}</LabelOption>
                    </Option>
                  </Auto>
              )}
              <Auto noGutter>
                <Option noBorder borderRight>
                  <LabelOption noBorder>IMPRO {improData?.round_number}</LabelOption>
                </Option>
              </Auto>
              <Auto noGutter>
                <MatchTimer>
                  <Period>Période {matchData?.current_period} : </Period>
                  <Timer timeLeft={chronoMatch || 0} size={4} />
                </MatchTimer>
              </Auto>
              {(caucus ||impro) && improData?.theme?.nbPlayer && (
                  <Auto noGutter>
                    <Option>
                      <LabelOption noBorder>Nombre de joueurs: {improData?.theme?.nbPlayer}</LabelOption>
                    </Option>
                  </Auto>
              )}
            </Grid>
          </RoundOptions>
      ) : (
          ""
      )}
    </>
  );
}

export default ScoreScreenTheme2;

const MatchTimer = styled.div`
  align-items: center;
  display: inline-flex;
  overflow: hidden;
  text-align: center;

  & div > div {
    background-color: transparent;
  }
`;

const Period = styled.div`
  ${({ theme }) => colorShade(theme.custom.color_foreground, -20)};
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size, 26))};
  font-weight: bold;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size, 10))};
`;

const RoundOptions = styled.div`
  background-color: ${({ theme }) => colorShade(theme.custom.color_background, 10)};
  border-radius: ${({ theme }) => (percentPixToRemList(theme.custom.font_size, 15))};
  margin: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,15, 0, 30))};
  overflow: hidden;
`;

const Option = styled.div<{ noBorder?: boolean, borderRight?:boolean }>`
  border-left: 1px solid ${({ theme, noBorder }) => (!noBorder ? colorShade(theme.custom.color_foreground, -20) : "transparent")};
  border-right: 1px solid ${({ theme, borderRight }) => (borderRight ? colorShade(theme.custom.color_foreground, -20) : "transparent")};
  display: flex;
  flex-direction: column;
`;

const LabelOption = styled.label<{ noBorder?: boolean, borderTop?:boolean }>`
  border-bottom: 1px solid ${({ theme, noBorder }) => (noBorder ? "transparent" : colorShade(theme.custom.color_foreground, -20))};
  border-top: 1px solid ${({ theme, borderTop }) => (!borderTop ? "transparent" : colorShade(theme.custom.color_foreground, -20))};
  font-weight: bold;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,15))};
`;

const ValueOption = styled.label`
  color: ${({ theme }) => theme.custom.color_main};
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,30))};
  font-weight: 500;
  font-weight: bold;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,15))};
`;

const ValueOptionTheme = styled.label`
  color: ${({ theme }) => theme.custom.color_main};
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,50))};
  font-weight: 500;
  font-weight: bold;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,15))};
`;
const Round = styled.div<{ noBorder?: boolean }>`
  border-bottom: 1px solid ${({ theme, noBorder }) => (noBorder ? "transparent" : colorShade(theme.custom.color_foreground, -20))};
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,28))};
  font-weight: bold;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,20))};
  text-transform: uppercase;
`;

const Vote = styled.div`
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,80))};
  font-weight: bold;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,10))};
`;

const EndMatchMessage = styled.div`
  align-items: center;
  background-color: ${({ theme }) => colorShade(theme.custom.color_background, 10)};
  border-radius: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,15))};
  display: flex;
  justify-content: center;
  height: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,288))};
  margin: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,30, 0))};
`;

