import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";

export const TeamLogo = ({
  teamId,
  readOnly,
}: {
  teamId: "team_left" | "team_right";
  readOnly?: boolean;
}) => {
  const inputRef = useRef();
  const [logo, setLogo] = useState<string>();

  const handleUpload = (e: ChangeEvent) => {
    const files =
      inputRef.current && (inputRef.current as HTMLInputElement)?.files?.[0];
    if (files) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      fileReader.addEventListener("load", function () {
        localStorage.setItem(`logo_${teamId}`, this.result as string);
        setLogo(this.result as string);
      });
    }
  };
  const selectFile = (e: Event) => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };

  const removeLogo = () => {
    localStorage.setItem(`logo_${teamId}`, "");
    setLogo(undefined);
  };

  useEffect(() => {
    const img = localStorage.getItem(`logo_${teamId}`);
    if (img) setLogo(img as string);
  }, []);

  return (
    <Wrapper img={logo as string}>
      {!logo && <TeamIcon className="icon icon-team" />}
      {!readOnly && (
        <>
          <UploadButton
            onClick={(e: any) => (!logo ? selectFile(e) : removeLogo())}
          >
            <i className={`icon icon-${!logo ? "plus" : "trash"}`} />
          </UploadButton>
          <InputStyled
            ref={inputRef as any}
            type="file"
            onChange={(e: ChangeEvent) => handleUpload(e)}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ img: string }>`
  align-items: center;
  background-image: ${({ img, theme }) =>
    img ? `url(${img})` : theme.colors.text};
  background-position: center center;
  background-size: cover;
  border: 3px solid ${({ theme }) => theme.colors.brand};
  box-shadow: 0 0 10px ${({ theme }) => theme.colors.brand};
  border-radius: 50%;
  display: flex;
  height: ${pixToRem(80)};
  justify-content: center;
  margin-bottom: ${pixToRem(15)};
  overflow: hidden;
  position: relative;
  text-align: center;
  width: ${pixToRem(80)};

  .icon-team {
    display: ${({ img }) => (img ? "none" : "inline-block")};
  }

  &:hover {
    button {
      color: ${({ theme }) => theme.colors.text};
      opacity: 1;
    }
  }
`;

const InputStyled = styled.input`
  visibility: hidden;
  opacity: 0;
  position: absolute;
`;

const UploadButton = styled.button`
  background: ${({ theme }) => theme.colors.brand};
  border: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  transition: opacity 300ms ease;

  i {
    font-size: ${pixToRem(20)};
  }
`;

const TeamIcon = styled.i`
  font-size: ${pixToRem(90)};
  line-height: 0;
`;
