import styled from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";

export const SectionWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.text};
    padding: ${pixToRem(20, 30, 15)};

    &:last-child {
        border: none;
    }
`;

export const SectionWrapperFocus = styled.div`
    background-color: ${({ theme }) => theme.colors.bodyTertiary};
    border-radius: ${pixToRem(15)};
    margin: ${pixToRem(-2, -2, 0)};
    padding: ${pixToRem(20, 40)};
`;

export const SectionTitle = styled.div`
    border-bottom: 3px solid ${({ theme }) => theme.colors.brand};
    color: ${({ theme }) => theme.colors.brand};
    display: inline-block;
    font-size: ${pixToRem(18)};
    font-weight: bold;
    text-transform: uppercase;
`;