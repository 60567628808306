import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { percentPixToRemList,percentPix } from "../../../styles/theme.mixins";
import { TeamLabelType, TeamType } from "../../../type";

export const LogoTeam = ({
  teamId,
  teamData,
  active,
}: {
  teamId: TeamLabelType;
  teamData: TeamType | undefined;
  active?: boolean;
}) => {
  const logo_left = localStorage.getItem(`logo_team_left`);
  const logo_right = localStorage.getItem(`logo_team_right`);

  const [penalities, setPenalities] = useState<string[]>();
  const [score, setScore] = useState<number>();
  const [pulseLogo, setPulseLogo] = useState<string>();
  const { custom, score: scoreTheme } = useTheme();

  useEffect(() => {
    if (teamData) {
      if (teamData?.penalities?.length !== penalities?.length) {
        setPulseLogo(scoreTheme.colors.danger);
      }
      if (teamData?.score + teamData?.gift_points !== score) {
        setPulseLogo(custom.color_main);
      }
      setPenalities(teamData?.penalities);
      setScore(teamData?.score + teamData?.gift_points);
    }
  }, [teamData]);

  useEffect(() => {
    setTimeout(() => {
      setPulseLogo(undefined);
    }, 800);
  }, [pulseLogo]);

  if (!logo_right || !logo_left) return null;
  return (
    <LogoImg
      src={teamId === "team_left" ? logo_left : logo_right}
      pulse={pulseLogo || active ? custom.color_main : ''}
      use_vote_color={teamData?.use_vote_color}
      vote_color={teamData?.vote_color}
    />
  );
};

const LogoImg = styled.div<{ src: string; pulse: string | undefined, use_vote_color: boolean | undefined, vote_color:string | undefined }>`
  animation: ${({ pulse }) => (pulse ? "circle-pulse 300ms ease" : "none")};
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-size: cover;
  border: ${({ theme, use_vote_color }) => (percentPix(theme.custom.font_size,use_vote_color?35:5))}px solid
    ${({ vote_color, pulse }) => (pulse ? pulse : vote_color)};
  border-radius: 50%;
  box-shadow: 0 0 ${({ theme }) => (percentPix(theme.custom.font_size,20))}px
    ${({ vote_color, pulse }) => (pulse ? pulse : vote_color)};
  height: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,230))};
  margin: 0 auto;
  position: relative;
  width: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,230))};
  z-index: 10;

  &:before {
    // animation: ${({ pulse }) => (pulse ? "wave-pulse 300ms ease" : "none")};
    // box-shadow: 0 0 50px ${({ pulse }) => pulse};
    animation: ${({ pulse }) =>
      pulse ? "wave-pulse 0.6s cubic-bezier(0.5, 0.5, 0, 1)" : "none"};
    background-color: ${({ pulse }) => pulse};
    border-radius: 50%;
    content: "";
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  @keyframes circle-pulse {
    0% {
      transform: scale(1); /* scaling to 0 */
    }
    50% {
      transform: scale(1.2); /* increasing the size */
    }
    100% {
      transform: scale(1); /* seeting back to initial size */
    }
  }

  @keyframes wave-pulse {
    0% {
      opacity: 0.4;
    }

    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
`;
