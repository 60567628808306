import { Button } from "../../../components/Button";
import { InputField } from "../../../components/InputField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid"
import { SectionTitle } from "../styles/SectionStyles"

export const FormHymn = ({ hideScreenButtons }: { hideScreenButtons?: boolean }) => {
  const { data, actions } = useLocalstorageProvider();

  const matchData = data?.matchData;
  
  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Hymne</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button isOnDark onClick={() => actions.showScreen('screen_hymn_team_a')}>Focus {matchData?.team_left?.name}</Button>&nbsp;
            <Button isOnDark onClick={() => actions.showScreen('screen_hymn_team_b')}>Focus {matchData?.team_right?.name}</Button>&nbsp;
            <Button icon="screen" />
          </Shrink>
        )}
      </Grid>
      <Grid>
        <Col md={12}>
          <InputField
            value={matchData?.hymn_title || ""}
            label="Titre de l'écran"
            type="text"
            handleChange={(e: any) =>
              actions.handleMatchChange(e.target.value, "hymn_title")
            }
          />
        </Col>
      </Grid>
    </>
  );
};
