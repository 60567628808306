import { useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { durationToString } from "../../../helpers/timeFormat";
import { Col, Grid } from "../../../styles/components/Grid";
import { pixToRem } from "../../../styles/theme.mixins";
import { TeamType, ImproType } from "../../../type";

const HistoryRow = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => (
  <RowContent>
    <Grid>
      <Col md={6}>{label} :</Col>
      <Col md={6}>
        <strong>{value}</strong>
      </Col>
    </Grid>
  </RowContent>
);

const HistoryTeam = ({ team }: { team: TeamType }) => (
  <RowContent>
    <HistoryRow label="Score équipe A" value={team?.score} />
    <HistoryRow label="Points bonus" value={team?.gift_points} />
    <HistoryRow label="Pénalités" value={team?.penalities?.length || 0} />
    {team?.penalities?.map((penality, index) => (
      <TeamPenalities key={`penality_${index}`}>
        {index + 1} - {penality}
      </TeamPenalities>
    ))}
  </RowContent>
);

export const HistoryItem = ({ improData }: { improData: ImproType }) => {
  const [showPanel, setShowPanel] = useState(false);

  return (
    <Panel>
      <PanelHeader>
        <h3>
          Période: {improData?.period} - Impro {improData?.round_number}
        </h3>
        <Button onClick={() => setShowPanel(!showPanel)}>
          {showPanel ? "Masquer" : "Afficher"}
        </Button>
      </PanelHeader>
      {showPanel && (
        <RoundContent>
          <Grid>
            <Col md={6}>
              <HistoryRow label="Thème" value={improData?.theme?.theme} />
            </Col>
            <Col md={6}>
              <HistoryRow
                label="Catégorie"
                value={improData?.theme?.category}
              />
            </Col>
            <Col md={6}>
              <HistoryRow label="Type" value={improData?.theme?.type} />
            </Col>
            <Col md={6}>
              <HistoryRow label="Duration" value={durationToString(improData?.theme?.duration)} />
            </Col>
            <Col md={6}>
              <HistoryRow
                label="Nb joueurs"
                value={improData?.theme?.nbPlayer}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={6}>
              <h4>
                <u>EQUIPE A</u>
              </h4>
              <HistoryTeam team={improData?.team_left} />
            </Col>
            <Col md={6}>
              <h4>
                <u>EQUIPE B</u>
              </h4>
              <HistoryTeam team={improData?.team_right} />
            </Col>
          </Grid>
        </RoundContent>
      )}
    </Panel>
  );
};

const Panel = styled.div`
  margin-bottom: ${pixToRem(15)};
`;

const PanelHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.body};
  display: flex;
  padding: ${pixToRem(15)};

  h3 {
    flex: 1 1 0;
    margin: 0;
  }
`;

const RoundContent = styled.div`
  background: ${({ theme }) => theme.colors.body};
  border-top: 1px solid ${({ theme }) => theme.colors.bodySecondary};
  color: ${({ theme }) => theme.colors.text};
  padding: ${pixToRem(25)};
`;

const RowContent = styled.div`
  padding: ${pixToRem(5, 0)};
`;

const TeamPenalities = styled.div`
  font-size: ${pixToRem(16)};
  margin: ${pixToRem(10, 0)};
`;
