import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";

export const Digit = ({
  value,
  size = 1,
  customColor,
}: {
  value: number;
  size?: number;
  customColor?: string;
}) => {
  const [color, setColor] = useState<string>("");
  const theme = useTheme();
  size = (size / theme.custom.font_size * 100);

  useEffect(() => {
    if (customColor) {
      setColor(customColor);
    } else {
      setColor(theme.custom.color_foreground);
    }
  }, [customColor, theme]);

  return (
    <>
      {theme.custom.use_digit ? (
        <DigitStyled size={size}>
          <Part className="d1" size={size} color={color} value={value || 0}></Part>
          <Part className="d2" size={size} color={color} value={value || 0}></Part>
          <Part className="d3" size={size} color={color} value={value || 0}></Part>
          <Part className="d4" size={size} color={color} value={value || 0}></Part>
          <Part className="d5" size={size} color={color} value={value || 0}></Part>
          <Part className="d6" size={size} color={color} value={value || 0}></Part>
          <Part className="d7" size={size} color={color} value={value || 0}></Part>
        </DigitStyled>
      ) : (
        <Numerical color={color} size={size}>
          {value || "0"}
        </Numerical>
      )}
    </>
  );
};

// NUMERICAL STYLES

const Numerical = styled.div<{ size: number; color: string }>`
  color: ${({ color, theme }) => color || theme.custom.color_foreground};
  display: inline-block;
  font-size: ${({ size }) => pixToRem(250 / size)};
  font-weight: 600;
  line-height: 0.88;
`;

// DIGITAL STYLES

const borderStyle = (
  position: "top" | "bottom" | "left" | "right",
  pseudo: "after" | "before",
  size: number,
  color: string
) => `
  &:${pseudo} {
    position: absolute;
    border-width: ${pixToRem(10 / size, 10 / size)};
    ${position}: ${pixToRem(-20 / size)};
    border-${
      position === "bottom"
        ? "top"
        : position === "top"
        ? "bottom"
        : position === "left"
        ? "right"
        : "left"
    }-color: ${color};
  }
`;

const activeStyle = (
  selector: string,
  color: string,
  value: number,
  compare: number
) => `
  ${
    value === compare
      ? `
          ${selector} {
            opacity: 1;
            box-shadow: 0 0 10px ${color};
          }
        `
      : ``
  }
`;

const DigitStyled = styled.div<{ size: number }>`
  width: ${({ size }) => pixToRem(120 / size)};
  height: ${({ size }) => pixToRem(218 / size)};
  text-align: left;
  position: relative;
  display: inline-block;
  margin: 0 ${({ size }) => pixToRem(10 / size)}; ;
`;

const Part = styled.span<{ size: number; color: string; value: number }>`
  background-color: ${({ color, theme }) =>
    color || theme.custom.color_foreground};
  position: absolute;
  opacity: 0.1;
  transition: all 0.4s;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: ${({ size }) => pixToRem(10 / size)} solid transparent;
    transition: all 0.4s;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: ${({ size }) => pixToRem(10 / size)} solid transparent;
    transition: all 0.4s;
  }

  &.d1 {
    height: ${({ size }) => pixToRem(20 / size)};
    width: ${({ size }) => pixToRem(76 / size)};
    top: 0;
    left: ${({ size }) => pixToRem(22 / size)};

    ${({ size, color }) => borderStyle("left", "before", size, color)}
    ${({ size, color }) => borderStyle("right", "after", size, color)}
  }

  &.d2 {
    height: ${({ size }) => pixToRem(20 / size)};
    width: ${({ size }) => pixToRem(74 / size)};
    top: ${({ size }) => pixToRem(99 / size)};
    left: ${({ size }) => pixToRem(23 / size)};

    ${({ color, size }) => borderStyle("left", "before", size, color)}
    ${({ color, size }) => borderStyle("right", "after", size, color)}
  }

  &.d3 {
    height: ${({ size }) => pixToRem(20 / size)};
    width: ${({ size }) => pixToRem(76 / size)};
    bottom: 0;
    left: ${({ size }) => pixToRem(22 / size)};

    ${({ color, size }) => borderStyle("left", "before", size, color)}
    ${({ color, size }) => borderStyle("right", "after", size, color)}
  }

  &.d4 {
    height: ${({ size }) => pixToRem(76 / size)};
    width: ${({ size }) => pixToRem(20 / size)};
    top: ${({ size }) => pixToRem(22 / size)};
    left: 0;

    ${({ color, size }) => borderStyle("top", "before", size, color)}
    ${({ color, size }) => borderStyle("bottom", "after", size, color)}
  }

  &.d5 {
    height: ${({ size }) => pixToRem(76 / size)};
    width: ${({ size }) => pixToRem(20 / size)};
    top: ${({ size }) => pixToRem(22 / size)};
    right: 0;

    ${({ color, size }) => borderStyle("top", "before", size, color)}
    ${({ color, size }) => borderStyle("bottom", "after", size, color)}
  }

  &.d6 {
    height: ${({ size }) => pixToRem(76 / size)};
    width: ${({ size }) => pixToRem(20 / size)};
    bottom: ${({ size }) => pixToRem(22 / size)};
    left: 0;

    ${({ color, size }) => borderStyle("top", "before", size, color)}
    ${({ color, size }) => borderStyle("bottom", "after", size, color)}
  }

  &.d7 {
    height: ${({ size }) => pixToRem(76 / size)};
    width: ${({ size }) => pixToRem(20 / size)};
    bottom: ${({ size }) => pixToRem(22 / size)};
    right: 0;

    ${({ color, size }) => borderStyle("top", "before", size, color)}
    ${({ color, size }) => borderStyle("bottom", "after", size, color)}
  }

  /* ZERO */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d3,&.d4,&.d5,&.d6,&.d7", color, value, 0)}
  /* ONE */
  ${({ color, value }) => activeStyle("&.d5,&.d7", color, value, 1)}
  /* TWO */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d2,&.d3,&.d5,&.d6", color, value, 2)}
  /* THREE */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d2,&.d3,&.d5,&.d7", color, value, 3)}
  /* FOUR */
  ${({ color, value }) => activeStyle("&.d2,&.d4,&.d5,&.d7", color, value, 4)}
  /* FIVE */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d2,&.d3,&.d4,&.d7", color, value, 5)}
  /* SIX */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d2,&.d3,&.d4,&.d6,&.d7", color, value, 6)}
  /* SEVEN */
  ${({ color, value }) => activeStyle("&.d1,&.d5,&.d7", color, value, 7)}
  /* EIGHT */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d2,&.d3,&.d4,&.d5,&.d6,&.d7", color, value, 8)}
  /* NINE */
  ${({ color, value }) =>
    activeStyle("&.d1,&.d2,&.d3,&.d4,&.d5,&.d7", color, value, 9)}
`;
