import styled from "styled-components";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Col, Grid } from "../../../styles/components/Grid";
import { TeamLabelType } from "../../../type";
import { LogoTeam } from "../ui/LogoTeam";

export const HymnScreen = ({ team }: { team: TeamLabelType }) => {
  const {
    data: { matchData },
  } = useLocalstorageProvider();

  return (
    <>
      <h1>{matchData?.hymn_title}</h1>
      <Grid vCenter="center">
        <Col md={6} order={matchData?.invert_team_display ? 1 : 0}>
          <TeamWrapper active={team === "team_left"}>
            <LogoTeam
              teamId="team_left"
              teamData={matchData?.team_left}
              active={team === "team_left"}
            />
            <h2>{matchData?.team_left?.name}</h2>
          </TeamWrapper>
        </Col>
        <Col md={6} order={matchData?.invert_team_display ? 0 : 1}>
          <TeamWrapper active={team === "team_right"}>
            <LogoTeam
              teamId="team_right"
              teamData={matchData?.team_right}
              active={team === "team_right"}
            />
            <h2>{matchData?.team_right?.name}</h2>
          </TeamWrapper>
        </Col>
      </Grid>
    </>
  );
};

const TeamWrapper = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  transition: transform 300ms ease-in, opacity 300ms ease-in;
  transform: scale(${({ active }) => (active ? 1.5 : 0.8)});
`;
