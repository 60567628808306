import { useLocalstorageProvider } from "../../../../hooks/LocalstorageProvider";
import { ScoreScreenType } from "../../../../type";
import ScoreScreenTheme1 from "./ScoreScreenTheme1";
import ScoreScreenTheme2 from "./ScoreScreenTheme2";
import ScoreScreenTheme3 from "./ScoreScreenTheme3";

function ScoreScreen(props: ScoreScreenType) {
  const {
    data: { screenData },
  } = useLocalstorageProvider();

  if (screenData?.theme.use_layout === "1") {
    return <ScoreScreenTheme1 {...props} />;
  }
  if (screenData?.theme.use_layout === "2")
  {
    return <ScoreScreenTheme2 {...props} />;
  }
  return <ScoreScreenTheme3 {...props} />;
}

export default ScoreScreen;
