import { ChronoControl } from "../../components/ChronoControl";
import { GridContainer } from "../../styles/components/Grid";
import { Header } from "./ui/Header";
import { useLocalstorageProvider } from "../../hooks/LocalstorageProvider";
import { HistoryPanel } from "./panels/HistoryPanel";
import { useState } from "react";
import { AccordionItem } from "./ui/AccordionItem";
import { MatchPanelV2 } from "./panels/MatchPanelV2";
import styled from "styled-components";
import { pixToRem } from "../../styles/theme.mixins";
import { useParams } from "react-router-dom";
import { ApiCalls } from "../../components/ApiCalls";

function Administration() {
  const { data, actions } = useLocalstorageProvider();
  let historyData = data?.historyData;
  const [activeItem, setActiveItem] = useState(0);
  const { id, token } = useParams();

  if (id && token) {
    ApiCalls(id, token, data, actions);
  }

  return (
    <div className="admin">
      <GridContainer>
        <Header data={data} clearAll={actions.clearAll} />

        <MatchPanelV2 />

        <AccordionItem
          title={`Historique (${historyData?.length || 0})`}
          isActive={activeItem === 7}
          setActive={() =>
            activeItem === 7 ? setActiveItem(0) : setActiveItem(7)
          }
        >
          <HistoryPanel />
        </AccordionItem>
      </GridContainer>
    </div>
  );
}

export default Administration;

