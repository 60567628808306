import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { PENALITY_LABELS } from "../../../constants";
import { TeamType, ImproType, TeamLabelType } from "../../../type";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";

export const PenalitiesLight = ({
  data,
  teamId,
}: {
  data: TeamType;
  handleChange: any;
  teamId: TeamLabelType;
}) => {
  const theme = useTheme();
  const [penalities, setPenalities] = useState<string[]>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const {
    actions,
    data: { matchData },
  } = useLocalstorageProvider();

  const handleAddPenality = (penality: string) => {
    setPenalities([...(penalities || []), penality]);
    setShowAddModal(false);
  };

  const handleRemovePenality = (index: number) => {
    if (penalities) {
      let data = [
        ...penalities.splice(0, index),
        ...penalities.splice(index + 1, penalities?.length),
      ];
      setPenalities(data);
    }
  };

  useEffect(() => {
    // Persists changes
    const otherTeamId = teamId === "team_left" ? "team_right" : "team_left";

    // Update team penalities & other team gift points
    const bonusPoint = penalities?.length
      ? Math.floor(penalities?.length / 3)
      : 0;
    const newData = {
      ...matchData,
      [teamId]: { ...matchData?.[teamId], penalities: penalities || null },
      [otherTeamId]: {
        ...matchData?.[otherTeamId],
        gift_points: bonusPoint,
      },
    } as Partial<ImproType>;

    actions.setMatchData(newData);
  }, [penalities]);

  useEffect(() => {
    // If data is empty, reset state. (next round event)
    if (data?.penalities?.length === 0) {
      setPenalities(undefined);
    }

    if (!penalities) {
      setPenalities(data?.penalities);
    }
  }, [data?.penalities]);

  return (
    <>
      <Grid vCenter="center">
        <Col xl={12}>
          <hr style={{ borderColor: theme.colors.brand, padding: 0 }} />
        </Col>
        <Auto>
          <Grid vCenter="center">
            <Shrink>Pénalités : {penalities?.length || 0}&nbsp;</Shrink>
            <Shrink>
              <Button
                icon="list"
                onClick={() => setShowListModal(!showListModal)}
                disabled={!penalities || penalities.length === 0}
              />
            </Shrink>
            <Shrink>
              <Button
                icon="plus"
                onClick={() => setShowAddModal(!showAddModal)}
              />
            </Shrink>
          </Grid>
        </Auto>
        <Shrink>Point(s) fautes : {data?.gift_points || 0}</Shrink>
      </Grid>
      {showListModal && (
        <Modal closeModal={() => setShowListModal(false)}>
          {data?.penalities?.map((penality, index) => (
            <PanelRow key={`penality_${index}`}>
              <Penality>
                {index + 1} - {penality}
              </Penality>
              <Button
                icon="trash"
                actionType="danger"
                onClick={() => handleRemovePenality(index)}
              />
            </PanelRow>
          ))}
        </Modal>
      )}

      {showAddModal && (
        <Modal closeModal={() => setShowAddModal(false)}>
          <h2>Choisissez une pénalité</h2>
          {PENALITY_LABELS?.map((penality, index) => (
            <LabelButton
              key={`label_${index}`}
              onClick={() => handleAddPenality(penality)}
            >
              {penality}
            </LabelButton>
          ))}
        </Modal>
      )}
    </>
  );
};

const Panel = styled.div``;

const PanelRow = styled.div`
  align-items: center;
  font-size: ${pixToRem(14)};
  display: flex;
  padding: ${pixToRem(1, 0)};
`;

const Penality = styled.div`
  flex: 1 1 0;
`;

const LabelButton = styled.button`
  background: transparent;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.text};
  box-shadow: none;
  display: block;
  font-size: ${pixToRem(16)};
  font-weight: 500;
  outline: none;
  padding: ${pixToRem(15)};
  text-align: center;
  width: 100%;
`;
