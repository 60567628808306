import { useEffect } from "react";
import { useFetchApi, usePostApi } from "../hooks/ApiProvider";
import {ImproType, MatchType, ScreenType} from "../type";

export const ApiCalls = (
    id: string,
    token: string,
    data: {
          matchData: MatchType | undefined;
          improData: ImproType | undefined;
          screenData: ScreenType | undefined;
          historyData: ImproType[]
    },
    actions: any
) => {
  const { apiData } = useFetchApi({ eventId: id, token: token });
  const { responseMessage } = usePostApi({ eventId: id, token: token, livescoreData: data });

  useEffect(() => {
    if (apiData) {
      localStorage.clear();
      actions.initDataFromAPI(apiData);
    }

    if (responseMessage) {
      console.log(responseMessage);
    }
  }, [apiData]);
}
