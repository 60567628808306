import { useEffect, useState } from "react";
import styled from "styled-components";
import { pixToRem } from "../styles/theme.mixins";
import { ChromePicker } from "react-color";
import tinycolor from "tinycolor2";

import { Button } from "./Button";
import { useClickOutside } from "../hooks/useClickOutside";

export const ColorField = ({
  label,
  value,
  handleChange,
}: {
  value: string;
  label?: string;
  handleChange: any;
}) => {
  const [colorHex, setColorHex] = useState<string>();
  const [textColor, setTextColor] = useState<string>();
  const [showColorPanel, setShowColorPanel] = useState<boolean>();

  const ref = useClickOutside(() => {
    setShowColorPanel(false);
  });

  useEffect(() => {
    if (value) {
      setColorHex(value);
    }
  }, [value]);

  useEffect(() => {
    if (colorHex) {
      const color = tinycolor
        .mostReadable(colorHex, ["#fff", "#000"])
        .toString("hex");
      setTextColor(color);
      handleChange({ hex: colorHex });
    }
  }, [colorHex]);

  return (
    <Wrapper ref={ref}>
      {label && <LabelStyled>{label} :</LabelStyled>}
      <FieldWrapper>
        {value && (
          <ColorPreview
            style={{ backgroundColor: colorHex, color: textColor }}
            onClick={() => setShowColorPanel(!showColorPanel)}
          >
            {colorHex}
          </ColorPreview>
        )}
        &nbsp;
        <Button
          icon="theme"
          onClick={() => setShowColorPanel(!showColorPanel)}
          style={{ flex: "0 0 auto" }}
        />
      </FieldWrapper>
      {showColorPanel && (
        <PickerWrapper>
          <ChromePicker
            color={colorHex}
            onChangeComplete={(e: any) => setColorHex(e.hex)}
          />
        </PickerWrapper>
      )}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const LabelStyled = styled.label`
  display: block;
  font-size: ${pixToRem(16)};
  margin-right: ${pixToRem(10)};
  text-align: right;
`;

export const FieldWrapper = styled.div`
  display: flex;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const ColorPreview = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.bodySecondary};
  border-radius: ${pixToRem(5)};
  flex: 1 1 0;
  font-size: ${pixToRem(12)};
  padding: ${pixToRem(10)};
  min-width: ${pixToRem(120)};
`;

export const PickerWrapper = styled.div`
  position: absolute;
`;
