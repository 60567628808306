import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { pixToRem, colorShade } from "../../../styles/theme.mixins";
import { Digit } from "./Digit";

export const Timer = ({
  timeLeft,
  size = 3,
}: {
  timeLeft: number;
  size?: number;
}) => {
  const [color, setColor] = useState<string>("");
  const [minDigits, setMinDigits] = useState<number[]>([]);
  const [secDigits, setSecDigits] = useState<number[]>([]);
  const { custom, score: scoreTheme } = useTheme();
  const theme = useTheme();
  const top_dot = theme.custom.chrono_dot_top;
  const down_dot = theme.custom.chrono_dot_down;
  size = (size / theme.custom.font_size * 100);


  useEffect(() => {
    const { min, sec } = formatTime(timeLeft);

    const s = checkNum(sec);
    setSecDigits(s);

    const m = checkNum(min);
    setMinDigits(m);
  }, [timeLeft]);

  useEffect(() => {
    /*
    if (timeLeft <= 5) {
      setColor(scoreTheme.colors.danger);
    } else if (timeLeft <= 10) {
      setColor(scoreTheme.colors.warning);
    } else {
      setColor(custom.color_foreground);
    }
     */
    setColor(custom.color_foreground);
  }, [scoreTheme, timeLeft, custom]);

  //const color = custom.color_foreground;
  const formatTime = (startTimeSec: number) => {
    const diffM = Math.floor(startTimeSec / 60);
    const diffS = startTimeSec - diffM * 60;

    return { min: diffM, sec: diffS };
  };

  const checkNum = (number: number) => {
    let num = number
      .toString()
      .split("")
      .map((item: string) => parseInt(item));

    if (num.length < 2) {
      return [0, ...num];
    } else {
      return num;
    }
  };

  return (
    <>
      <Clock id="clock">
        <Display size={size} color={color}>
          <Digits>
            <Digit
              value={minDigits?.[0] || 0}
              size={size}
              customColor={color}
            />
            <Digit
              value={minDigits?.[1] || 0}
              size={size}
              customColor={color}
            />

            <Dots className="dots" size={size} color={color} top_dot={top_dot} down_dot={down_dot} />

            <Digit
              value={secDigits?.[0] || 0}
              size={size}
              customColor={color}
            />
            <Digit
              value={secDigits?.[1] || 0}
              size={size}
              customColor={color}
            />
          </Digits>
        </Display>
      </Clock>
    </>
  );
};

const Clock = styled.div`
  display: inline-block;
`;

const Display = styled.div<{ size: number }>`
  background-color: ${({ theme }) =>
    colorShade(theme.custom.color_background, 10)};
  text-align: center;
  padding: ${({ size }) => pixToRem(60 / size)};
  border-radius: 6px;
  position: relative;
  overflow: hidden;
`;

const Digits = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const Dots = styled.div<{ size: number; color: string; top_dot: number; down_dot: number; }>`
  width: ${({ size }) => pixToRem(20 / size)};
  position: relative;
  animation-name: dots-animate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  margin: ${({ size }) => pixToRem(0, 30 / size)};

  &:after,
  &:before {
    background-color: ${({ color }) => color};
    box-shadow: 0 0 5px ${({ color }) => color};
    background-color: ${({ color }) => color};
    box-shadow: 0 0 5px ${({ color }) => color};
    width: ${({ size }) => pixToRem(20 / size)};
    height: ${({ size }) => pixToRem(20 / size)};
    content: "";
    position: absolute;
    left: 0;
    top: ${({ size, top_dot }) => pixToRem(top_dot / size)};
  }

  &:after {
    top: ${({ size, down_dot }) => pixToRem(down_dot / size)};
  }

  @keyframes dots-animate {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;
