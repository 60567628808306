import "./theme.icons.css";
import "./theme.fonts.css";

export type BreakpointType = {
  width: number;
  gutter: number;
};

// BRAND THEME COLORS

const scoreTheme = {
  colors: {
    body: "#000000",
    bodySecondary: "#0f1620",
    text: "#ffffff",
    danger: "#ca4e32",
    warning: "#f7a130",
    digit: "#00d9ff",
    darkGray: '#333',
  },
};

export const lightTheme = {
  colors: {
    body: "#ccc",
    bodySecondary: "#bbb",
    text: "#210921",
    brand: "#1979f7",
    danger: "#ca4e32",
    warning: "#f7a130",
  },
  score: scoreTheme,
};

export const darkTheme = {
  colors: {
    body: "#3a3b3f",
    bodySecondary: "#536684",
    bodyTertiary: "#394858",
    text: "#ffffff",
    brand: "#c8539f",
    danger: "#b83051",
    warning: "#f7a130",
  },
  score: scoreTheme,
};

// RESPONSIVE RULES

export const gutterWidth = 18;
export const contentWidth = 1040;

export const smallBreakpoint: BreakpointType = {
  width: 640,
  gutter: 20,
};

export const mediumBreakpoint: BreakpointType = {
  width: 1024,
  gutter: 18,
};

export const largeBreakpoint: BreakpointType = {
  width: 1200,
  gutter: 18,
};

export const headerSize = {
  h1: 85,
  h2: 36,
  h3: 24,
};
