import styled from "styled-components";
import { Button } from "../../../components/Button";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { SectionWrapper } from "../styles/SectionStyles";
import { HistoryItem } from "../ui/HistoryItem";

export const HistoryPanel = () => {
  const { data, actions } = useLocalstorageProvider();
  const historyData = data?.historyData;

  return (
    <SectionWrapper>
      <HistoryHeader>
        <Button
          icon="trash"
          actionType="danger"
          onClick={() => actions.clearHistory()}
        >
          Effacer
        </Button>
      </HistoryHeader>
      <br />
      {historyData?.map((round, index) => (
        <HistoryItem key={index} improData={round} />
      ))}
    </SectionWrapper>
  );
};

const HistoryHeader = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 100%;
`;
