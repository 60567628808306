import dayjs from "dayjs";
import { useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { useAppProvider } from "../../../hooks/AppProvider";
import { MusicPanel } from "../panels/MusicPanel";
import { ThemePanel } from "./ThemePanel";
import { Modal } from "../../../components/Modal";
import { FormWelcome } from "../forms/FormWelcome";
import { FormTraining } from "../forms/FormTraining";
import { FormTeams } from "../forms/FormTeams";
import { FormHymn } from "../forms/FormHymn";
import { FormImpro } from "../forms/FormImpro";
import { FormCaucus } from "../forms/FormCaucus";
import { FormVote } from "../forms/FormVote";
import { FormEndPeriod } from "../forms/FormEndPeriod";
import { FormEndMatch } from "../forms/FormEndMatch";
import { Col, Grid } from "../../../styles/components/Grid";
import { pixToRem } from "../../../styles/theme.mixins";

export const Header = ({ data, clearAll }: any) => {
  // const { actions, darkMode } = useAppProvider();
  const { actions } = useAppProvider();
  const [showThemePanel, setShowThemePanel] = useState<boolean>();
  const [showMusicPanel, setShowMusicPanel] = useState<boolean>();
  const [showOption, setShowOption] = useState<boolean>();

  return (
    <HeaderStyled>
      <Title>
          <a href={"https://www.asso-planner.fr"} target={"_blank"}>
            <img src="/logo.svg" alt="livescore by Assoplanner" width="320px" />
          </a>
      </Title>
        <ButtonHeader>
            <a href={"https://www.asso-planner.fr/tutoriel/livescore/"} target={"_blank"}>
                <i>Tuto</i>
            </a>
        </ButtonHeader>
      <ButtonHeader icon="options-line" onClick={() => setShowThemePanel(!showThemePanel)}>
        Paramètres<br />généraux
      </ButtonHeader>
      {showThemePanel && (
        <ThemePanel closePanel={() => setShowThemePanel(false)} />
      )}
      &nbsp;
      <ButtonHeader icon="settings" onClick={() => setShowOption(showOption !== true)}>
        Paramètres<br />match
      </ButtonHeader>
      &nbsp;
      <ButtonHeader icon="screen" onClick={() => actions.openScorePopup()}>
        Écran<br />des scores
      </ButtonHeader>
      &nbsp;
      {/*
      <Button icon="download">
        <a
          href={`data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify({
              match: data?.matchData,
              history: [...data?.historyData, data?.improData],
            })
          )}`}
          download={`match-${dayjs().format("YYYYMMDDHHmmss")}.json`}
          target="_blank"
          rel="noreferrer"
        >
          Download JSON
        </a>
      </Button>
      &nbsp;
      <Button
        icon="theme"
        onClick={() => setShowMusicPanel(!showMusicPanel)}
      >
          Test lécteur musique
      </Button>
      */}
      &nbsp;
      <ButtonHeader onClick={() => clearAll()} icon="reset" actionType="danger">
        Réinitialiser
      </ButtonHeader>
      {showMusicPanel && (
        <MusicPanel closePanel={() => setShowMusicPanel(false)} />
      )}
      {showOption && (
        <Modal closeModal={() => setShowOption(false)} width={1200}>
          <Grid vCenter="center">
            <Col xs={12}>
              <FormTeams />
              <FormImpro />
            </Col>
            <Col xs={6}>
              <FormWelcome hideScreenButtons />
            </Col>
            <Col xs={6}>
              <FormTraining hideScreenButtons />
            </Col>
            <Col xs={6}>
              <FormHymn hideScreenButtons />
            </Col>
            <Col xs={6}>
              <FormVote hideScreenButtons />
            </Col>
            <Col xs={6}>
              <FormCaucus hideScreenButtons />
            </Col>
            <Col xs={6}>
              <FormEndPeriod hideScreenButtons />
            </Col>
            <Col xs={12}>
              <FormEndMatch hideScreenButtons />
            </Col>
          </Grid>
          <Button onClick={() => setShowOption(false)}>Fermer</Button>
        </Modal>
      )}
    </HeaderStyled>
  );
};

const HeaderStyled = styled.header`
  align-items: center;
  column-gap: ${pixToRem(15)};
  display: flex;
  flex-flow: row wrap;
  margin-bottom: ${pixToRem(30)};
`;

const Title = styled.h1`
  flex: 1 1 0;
  line-height: 0;

  img {
    display: inline-block;
    margin: 0;
    line-height: 0;
  }
`;

const ButtonHeader = styled(Button)`
  line-height: 1.2;
  text-align: left;

  i {
    font-size: ${pixToRem(32)};
  }
`;
