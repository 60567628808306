import styled from "styled-components";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Col, Grid } from "../../../styles/components/Grid";
import {colorShade, percentPixToRemList, pixToRem} from "../../../styles/theme.mixins";
import { TeamLabelType } from "../../../type";
import { LogoTeam } from "../ui/LogoTeam";

export const PresenceScreen = ({ team }: { team: TeamLabelType }) => {
  const {
    data: { screenData, matchData },
  } = useLocalstorageProvider();

  return (
    <>
      <h1>Appel des joueurs</h1>
      <Grid vCenter="center">
        <Col md={6}>
          <LogoTeam teamId={team} teamData={matchData?.[team]} />
          <h2>{matchData?.[team]?.name}</h2>
        </Col>
        <Col md={6}>
          <ULStyled>
            {screenData?.[`presence_${team}`]?.map((member) => (
              <LIStyled>
                <strong>{member}</strong>
              </LIStyled>
            ))}
          </ULStyled>
        </Col>
      </Grid>
    </>
  );
};

const ULStyled = styled.ul`
  margin: 0;
  padding: 0;
`;

const LIStyled = styled.li`
  background-color: ${({ theme }) =>
    colorShade(theme.custom.color_background, 10)};
  padding: ${pixToRem(15)};
  list-style: none;
  border-radius: ${({ theme }) => (percentPixToRemList(theme.custom.font_size, 15))};
  margin: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,30, 20, 0))};
  overflow: hidden;
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,35))};
`;
