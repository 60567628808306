import { useEffect, useState } from "react";
import styled from "styled-components";
import { useChrono } from "../../../../hooks/useChrono";
import { useAppProvider } from "../../../../hooks/AppProvider";
import { useLocalstorageProvider } from "../../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid } from "../../../../styles/components/Grid";
import { pixToRem, colorShade } from "../../../../styles/theme.mixins";
import { TeamScoreContent } from "../../ui/TeamScoreContent";
import { Timer } from "../../ui/Timer";
import { LogoTeam } from "../../ui/LogoTeam";
import { ScoreScreenType } from "../../../../type";
import { AudioVote } from "../../../../components/AudioVote";

function ScoreScreenTheme1({ impro, empty, caucus, vote, endPeriod, endMatch, title }: ScoreScreenType) {
  const [hideCenterCol, setHideCenterCol] = useState<boolean>();
  const {
    data: { matchData, improData, screenData },
  } = useLocalstorageProvider();

  const { actions } = useAppProvider();
  const { secondLeft: chronoMatch } = useChrono("timer_match");
  const { secondLeft: chronoRound } = useChrono("timer_round");
  const { secondLeft: chronoCaucus } = useChrono("timer_caucus");
  const { secondLeft: chronoPeriod } = useChrono("timer_pause_period");

  const handleStorageUpdate = () => {
    const darkMode = localStorage.getItem("darkMode");
    actions.setDarkMode(darkMode);
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageUpdate);
    return () => {
      window.removeEventListener("storage", handleStorageUpdate);
    };
  }, []);

  useEffect(() => {
    setHideCenterCol(empty || (vote && !screenData?.theme.vote_with_audio));
  }, [empty, vote, screenData?.theme.vote_with_audio]);

  return (
    <>
      {!endPeriod && !endMatch ? (
        <MatchTimer>
          <Period>Période: {matchData?.current_period}</Period>
          <Timer timeLeft={chronoMatch || 0} size={8} />
        </MatchTimer>
      ) : (
        ""
      )}
      {(caucus || impro) ? (
        <RoundOptions>
          <Grid noGutter>
            {improData?.theme?.type && (
                <Auto noGutter>
                  <Option noBorder>
                    <LabelOption>Type</LabelOption>
                    <ValueOption>{improData?.theme?.type}</ValueOption>
                  </Option>
                </Auto>
            )}
            {improData?.theme?.theme && (
              <Auto noGutter>
                <Option>
                  <LabelOption>Thème</LabelOption>
                  <ValueOption>{improData?.theme?.theme}</ValueOption>
                </Option>
              </Auto>
            )}
            {improData?.theme?.nbPlayer && (
                <Auto noGutter>
                  <Option>
                    <LabelOption>Nb. de joueurs</LabelOption>
                    <ValueOption>{improData?.theme?.nbPlayer}</ValueOption>
                  </Option>
                </Auto>
            )}
            {improData?.theme?.category && (
              <Auto noGutter>
                <Option>
                  <LabelOption>Catégorie</LabelOption>
                  <ValueOption>{improData?.theme?.category}</ValueOption>
                </Option>
              </Auto>
            )}
          </Grid>
        </RoundOptions>
      ) : (
        ""
      )}
      <Grid vCenter={!endMatch ? "center" : "flex-end"}>
        <Col md={hideCenterCol ? 5 : 4} order={matchData?.invert_team_display ? 2 : 0}>
          <LogoTeam teamId="team_left" teamData={improData?.team_left} />
          {matchData?.team_left && improData?.team_left && (
            <TeamScoreContent
              name={matchData.team_left.name}
              team={improData.team_left}
              penalities={matchData?.team_left.penalities}
              score={1 * improData.team_left?.score + matchData?.team_left?.gift_points}
              hidePenalities={caucus || endPeriod || endMatch}
            />
          )}
        </Col>
        <Col md={hideCenterCol ? 2 : 4} order={1}>
          {!empty && !endMatch && !(impro && improData?.theme?.useChrono === "Non") ? (
            <RoundTimer>
              {title && <Round>{title}</Round>}
              {!vote && !endMatch && !(caucus && improData?.caucus_useChrono === "Non") ? (
                <Timer timeLeft={(caucus ? chronoCaucus : endPeriod ? chronoPeriod : chronoRound) || 0} size={3} />
              ) : (
                ""
              )}
              {caucus && chronoRound && improData?.theme?.useChrono === "Oui" && (
                  <RoundOptions>
                    <Option noBorder>
                      <LabelOption>DURÉE</LabelOption>
                      <Timer timeLeft={(chronoRound) || 0} size={6} />
                    </Option>
                  </RoundOptions>
              )}
              {vote && (
                <>
                  <Vote>{improData?.vote_title || "--"}</Vote>
                  {screenData?.theme.vote_with_audio && (<AudioVote hideActions />)}
                </>
              )}
            </RoundTimer>
          ) : endMatch ? (
            <EndMatchMessage>{title && <Round noBorder>{title}</Round>}</EndMatchMessage>
          ) : (
            ""
          )}
        </Col>
        <Col md={hideCenterCol ? 5 : 4} order={matchData?.invert_team_display ? 0 : 2}>
          <LogoTeam teamId="team_right" teamData={improData?.team_right} />
          {matchData?.team_right && improData?.team_right && (
            <TeamScoreContent
              name={matchData.team_right.name}
              team={improData.team_right}
              penalities={matchData?.team_right.penalities}
              score={1 * improData.team_right?.score + matchData?.team_right?.gift_points}
              hidePenalities={caucus || endPeriod || endMatch}
            />
          )}
        </Col>
      </Grid>
    </>
  );
}

export default ScoreScreenTheme1;

const MatchTimer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => colorShade(theme.custom.color_background, 10)};
  border-radius: ${pixToRem(5)};
  display: inline-flex;
  overflow: hidden;
  text-align: center;
`;

const Period = styled.div`
  border-right: 1px solid ${({ theme }) => colorShade(theme.custom.color_foreground, -20)};
  font-size: ${pixToRem(24)};
  font-weight: bold;
  padding: ${pixToRem(10)};
`;
const RoundOptions = styled.div`
  background-color: ${({ theme }) => colorShade(theme.custom.color_background, 10)};
  border-radius: ${pixToRem(15)};
  margin: ${pixToRem(15, 0, 30)};
  overflow: hidden;

  & > div > div:last-child > div {
    border: 0;
  }
`;

const Option = styled.div<{ noBorder?: boolean }>`
  border-left: 1px solid ${({ theme, noBorder }) => (!noBorder ? colorShade(theme.custom.color_foreground, -20) : "transparent")};
  display: flex;
  flex-direction: column;
`;

const LabelOption = styled.label`
  border-bottom: 1px solid ${({ theme }) => colorShade(theme.custom.color_foreground, -20)};
  font-weight: bold;
  padding: ${pixToRem(15)};
`;

const ValueOption = styled.label`
  text-shadow: 0 0 10px ${({ theme }) => theme.custom.color_main};
  color: ${({ theme }) => theme.custom.color_main};
  font-weight: 500;
  text-transform: uppercase;
  padding: ${pixToRem(15)};
`;

const RoundTimer = styled.div`
  background-color: ${({ theme }) => colorShade(theme.custom.color_background, 10)};
  border-radius: ${pixToRem(15)};
  display: inline-block;
`;

const Round = styled.div<{ noBorder?: boolean }>`
  border-bottom: 1px solid ${({ theme, noBorder }) => (noBorder ? "transparent" : colorShade(theme.custom.color_foreground, -20))};
  font-size: ${pixToRem(28)};
  font-weight: bold;
  padding: ${pixToRem(20)};
  text-transform: uppercase;
`;

const Vote = styled.div`
  font-size: ${pixToRem(80)};
  font-weight: bold;
  padding: ${pixToRem(10)};
`;

const EndMatchMessage = styled.div`
  align-items: center;
  background-color: ${({ theme }) => colorShade(theme.custom.color_background, 10)};
  border-radius: ${pixToRem(15)};
  display: flex;
  height: ${pixToRem(288)};
  margin: ${pixToRem(30, 0)}
`;