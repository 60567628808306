import { ImproType, MusicType, ScreenType, TeamType } from "./type";

export const PENALITY_LABELS = [
  "Faute",
];

export const IMPRO_DURATION_DEFAULT = 3 * 60;
export const PERIOD_DURATION_DEFAULT = 45 * 60;
export const PAUSE_DURATION_DEFAULT = 15 * 60;
export const CAUCUS_DURATION_DEFAULT = 20;
export const TRAINING_DURATION_DEFAULT = 5 * 60;

export const THEME_YES_NO = ["Oui", "Non"];
export const THEME_TYPE_LABELS = ["Mixte", "Comparé"];
export const THEME_NBPLAYER_LABELS = ["1", "2", "Illimité"];
export const THEME_CATEGORY_LABELS = ["Libre", "Catégorie 1", "Catégorie 2"];

export const MEMBER_ROLE_LABELS = ["Joueur", "Capitaine", "Coach"];

export const matchKey = "match_data";
export const roundKey = "round_data";
export const historyKey = "history_data";
export const screenKey = "screen_data";
export const timerMatchKey = "timer_match";
export const timerRoundKey = "timer_round";
export const musicKey = "music_data";
