import { Button } from "../../../components/Button";
import { InputField } from "../../../components/InputField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";

export const FormWelcome = ({ hideScreenButtons }: { hideScreenButtons?: boolean }) => {
  const { data, actions } = useLocalstorageProvider();

  const matchData = data?.matchData;

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Bienvenue</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button icon="screen" onClick={() => actions.showScreen("screen_welcome")} />
          </Shrink>
        )}
      </Grid>
      <Grid vCenter="center">
        <Col md={12}>
          <InputField
            value={matchData?.welcome_title || ""}
            label="Titre de l'écran"
            type="text"
            handleChange={(e: any) => actions.handleMatchChange(e.target.value, "welcome_title")}
          />
        </Col>
      </Grid>
    </>
  );
};
