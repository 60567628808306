import { Button } from "../../../components/Button";
import { DurationInput } from "../../../components/DurationField";
import { TextareaField } from "../../../components/TextareaField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";

export const FormEndPeriod = ({
  hideScreenButtons,
}: {
  hideScreenButtons?: boolean;
}) => {
  const { data, actions } = useLocalstorageProvider();

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Fin de période</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button onClick={() => actions.showScreen("screen_empty")}>
              Écran vide
            </Button>
            &nbsp;
            <Button
              onClick={() => actions.showScreen("screen_end_period")}
              icon="screen"
            />
          </Shrink>
        )}
      </Grid>
      <Grid>
        <Col md={7}>
          <TextareaField
            label="Message"
            value={data?.screenData?.period_title || ""}
            handleChange={(e: any) =>
              actions.handleScreenChange(e.target.value, "period_title")
            }
          />
        </Col>
        <Col md={5}>
          <DurationInput
            buttonIncrement={15}
            minValue={15}
            maxValue={60 * 60 - 1}
            label="Durée fin de période"
            value={
              data?.screenData?.period_pause_duration ||
              data?.matchData?.pause_duration ||
              10 * 60
            }
            onChange={(value) =>
              actions.handleScreenChange(value, "period_pause_duration")
            }
          />
        </Col>
      </Grid>
    </>
  );
};
