import styled from "styled-components";
import { pixToRem } from "../styles/theme.mixins";

export const Button = (props: any) => {
  return (
    <ButtonStyled
      {...props}
      actionType={props.actionType}
      hasChildren={!!props.children}
    >
      {props.icon && (
        <Icon
          hasChildren={!!props.children}
          className={`icon icon-${props.icon}`}
        />
      )}
      {props.children}
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button<{
  isOnDark: boolean;
  isActive: boolean;
  hasChildren: boolean;
  actionType: string;
  disabled: boolean;
}>`
  align-items: center;
  background: ${({ theme, actionType, isOnDark, isActive }) =>
    actionType === "danger"
      ? theme.colors.danger
      : actionType === "default"
      ? theme.colors.text
      : actionType === "secondary"
      ? theme.colors.bodySecondary
      : actionType === "tertiary" && !isOnDark
      ? theme.colors.bodyTertiary
      : isOnDark && isActive
      ? theme.colors.body
      : isOnDark 
      ? theme.colors.text
      : theme.colors.brand};
  border: 0;
  color: ${({ isOnDark, isActive, theme }) => (isOnDark && isActive ? theme.colors.text : isOnDark ? theme.colors.body : "#fff")};
  border-radius: ${pixToRem(5)};
  display: flex;
  font-size: ${pixToRem(12)};
  font-weight: bold;
  justify-content: center;
  padding: ${pixToRem(8)};
  transition: color 250ms ease-out, background-color 250ms ease-out;

  i {
    vertical-align: middle;
  }

  &:hover {
    background: ${({ theme, isOnDark }) =>
      isOnDark ? theme.colors.body : theme.colors.text};
    color: ${({ theme, isOnDark }) =>
      isOnDark ? theme.colors.text : theme.colors.body};

    a {
      color: ${({ theme, isOnDark }) =>
        isOnDark ? theme.colors.text : theme.colors.body};
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  a {
    color: #fff;
  }
`;

const Icon = styled.i<{ hasChildren: boolean }>`
  margin-right: ${({ hasChildren }) => (hasChildren ? pixToRem(7) : 0)};
  font-size: ${({ hasChildren }) =>
    hasChildren ? pixToRem(16) : pixToRem(16)};
`;
