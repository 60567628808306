import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocalstorageProvider } from "../hooks/LocalstorageProvider";
import { Auto, Grid, Shrink } from "../styles/components/Grid";
import { pixToRem } from "../styles/theme.mixins";
import { Button } from "./Button";
import { InputField } from "./InputField";

export const AudioVote = ({ hideActions }: { hideActions?: boolean }) => {
  const [audio, setAudio] = useState<MediaStream>();
  const [audioAnalyzer, setAudioAnalyzer] = useState<AnalyserNode>();
  const [source, setSource] = useState<MediaStreamAudioSourceNode>();
  const [audioContext, setAudioContext] = useState<AudioContext>();
  const [audioData, setAudioData] = useState<number>(0);
  const [maxNoise, setMaxNoise] = useState<number>(0);
  const [maxDecibelInput, setMaxDecibelInput] = useState<number>(180);
  const [rafId, setRafId] = useState<any>();

  const {
    actions,
    data: { screenData },
  } = useLocalstorageProvider();

  useEffect(() => {
    if (screenData?.listen_vote_mic) {
      getMicrophone();
    }
  }, [screenData?.listen_vote_mic]);

  useEffect(() => {
    return () => {
      stopMicrophone();
    }
  }, []);

  useEffect(() => {
    if (audio && screenData?.listen_vote_mic) {
      const context = new window.AudioContext();
      const analyser = context.createAnalyser();
      const sourceAudio = context.createMediaStreamSource(audio);
      analyser.fftSize = 512;
      analyser.minDecibels = -127;
      analyser.maxDecibels = 0;
      analyser.smoothingTimeConstant = 0.4;

      sourceAudio.connect(analyser);
      const volumes = new Uint8Array(analyser.frequencyBinCount);

      setSource(sourceAudio);
      setAudioAnalyzer(analyser);
      setAudioContext(context);

      setRafId(
        setInterval(() => {
          analyser.getByteFrequencyData(volumes);
          let volumeSum = 0;
          for (const volume of volumes as any) volumeSum += volume;
          const averageVolume = volumeSum / volumes.length;
          // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
          const dec = (averageVolume * 100) / 127;
          setAudioData(dec);
        }, 100)
      );
    } else {
      clearInterval(rafId);
    }

    return () => {
      if (rafId) clearInterval(rafId);
      if (audioContext?.state !== 'closed') audioContext?.close();
      audioAnalyzer?.disconnect();
      source?.disconnect();
    };
  }, [screenData, screenData?.listen_vote_mic, audio]);

  useEffect(() => {
    if (audioData > maxNoise) {
      setMaxNoise(audioData);
      actions.handleImproChange(Math.floor(maxNoise), "vote");
    }
  }, [audioData]);

  const getMicrophone = async () => {
    const audioApi = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    setAudioData(0);
    setMaxNoise(0);
    setAudio(audioApi);
    actions.handleScreenChange(true, "listen_vote_mic");
  };

  const stopMicrophone = () => {
    audio?.getTracks().forEach((track) => track.enabled = false);
    audio?.getTracks().forEach((track) => track.stop());
    if (rafId) clearInterval(rafId);
    if (audioContext?.state !== 'closed') audioContext?.close();
    if (audioAnalyzer) audioAnalyzer.disconnect();
    if (source) source.disconnect();
    setAudio(undefined);
    setAudioData(0);
    actions.handleScreenChange(false, "listen_vote_mic");
  };

  const toggleMicrophone = () => {
    if (audio) {
      stopMicrophone();
    } else {
      getMicrophone();
    }
  };

  return (
    <>
      {!hideActions && (
        <Grid vCenter="center">
          <Shrink>Input Max</Shrink>
          <Shrink>
            <InputField
              type="text"
              handleChange={(e: any) => setMaxDecibelInput(e?.target?.value)}
              value={maxDecibelInput}
              style={{ width: "80px" }}
            />
          </Shrink>
          <Auto>{!hideActions && <Button onClick={toggleMicrophone}>{audio ? "Stop microphone" : "Get microphone input"}</Button>}</Auto>
        </Grid>
      )}
      <Grid>
        <Auto>
          <ProgressBar value={audioData} max={maxDecibelInput || 0} />
        </Auto>
      </Grid>
      <Grid>
        <Auto>
          <ProgressBar value={maxNoise} max={maxDecibelInput || 0} />
        </Auto>
      </Grid>
    </>
  );
};

const ProgressBar = ({ value, max }: { value: number; max: number }) => {
  if (!max) return null;
  return (
    <Bar>
      <InnerBar style={{ width: `${(value * 100) / max}%` }} />
    </Bar>
  );
};

const Bar = styled.div`
  background-color: ${({ theme }) => theme.colors.bodySecondary};
  border-radius: ${pixToRem(3)};
  display: block;
  height: ${pixToRem(20)};
  margin: ${pixToRem(0, 20)};
  overflow: hidden;
  width: ${pixToRem(200)};
`;

const InnerBar = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.custom.color_main} 0%,${theme.colors.warning} 70%,${theme.colors.danger} 100%)`};
  height: ${pixToRem(20)};
  animation: all 200ms ease;
`;
