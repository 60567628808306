import { AudioVote } from "../../../components/AudioVote";
import { Button } from "../../../components/Button";
import { InputField } from "../../../components/InputField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";

export const FormVote = ({ hideScreenButtons }: { hideScreenButtons?: boolean }) => {
  const { data, actions } = useLocalstorageProvider();

  const improData = data?.improData;
  const themeData = data?.screenData?.theme;

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Vote</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button icon="screen" onClick={() => actions.showScreen("screen_vote")} />
          </Shrink>
        )}
      </Grid>
      <Grid vCenter="start">
        <Auto>
          <InputField
              value={improData?.vote_title || ""}
              label="Titre de l'écran"
              type="text"
              handleChange={(e: any) => actions.handleImproChange(e.target.value, "vote_title")}
          />
        </Auto>
          {themeData?.vote_with_audio && (
              <Auto>
                  <Grid>
                    <Col lg={2}>&nbsp;</Col>
                    <Auto><AudioVote /></Auto>
                </Grid>
            </Auto>
        )}
      </Grid>
    </>
  );
};
