//import styled from "styled-components";
import styled from "styled-components";
import { useLocalstorageProvider } from "../../hooks/LocalstorageProvider";
import {colorShade, pixToRem, percentPix, percentPixToRemList} from "../../styles/theme.mixins";
import { WelcomeScreen } from "./panels/WelcomeScreen";
import { TrainingScreen } from "./panels/TrainingScreen";
import { PresenceScreen } from "./panels/PresenceScreen";
import { HymnScreen } from "./panels/HymnScreen";
import ScoreScreen from "./panels/ScoreScreen";
import { ScreenName } from "../../type";

function DisplayScreen() {
  const {
    data: { screenData, improData },
  } = useLocalstorageProvider();


  const views: Partial<Record<ScreenName, any>> = {
    screen_welcome: <WelcomeScreen />,
    screen_training: <TrainingScreen />,
    screen_presence_team_a: <PresenceScreen team="team_left" />,
    screen_presence_team_b: <PresenceScreen team="team_right" />,
    screen_hymn_team_a: <HymnScreen team="team_left" />,
    screen_hymn_team_b: <HymnScreen team="team_right" />,
    screen_empty: <ScoreScreen empty title="" />,
    screen_caucus: (
            <ScoreScreen caucus title={improData?.caucus_title} />
    ),
    screen_impro: (
      <ScoreScreen impro title={`Impro ${improData?.round_number}`} />
    ),
    screen_vote: (
            <ScoreScreen vote title={improData?.vote_title} />
    ),
    screen_end_period: (
      <ScoreScreen endPeriod title={screenData?.period_title} />
    ),
    screen_end_match: (
      <ScoreScreen endMatch title={screenData?.end_match_title} />
    ),
  };

  return (
    <GlobalScreen>
      <ScreenWrapper>
        <ScreenContent>{views?.[screenData?.show || ""]}</ScreenContent>
      </ScreenWrapper>
    </GlobalScreen>
  );
}

export default DisplayScreen;

const GlobalScreen= styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  width: 100%;
  background-color: ${({ theme }) => theme.custom.resize_screen_color};
`;

const ScreenWrapper = styled.div`
  background-color: ${({ theme }) => theme.custom.color_background};
  color: ${({ theme }) => theme.custom.color_foreground};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  padding: ${({ theme }) => (percentPix(theme.custom.font_size,25))}px;
  text-align: center;
  width: ${({ theme }) => 100 - (theme.custom.resize_screen ?(theme.custom.margin_left+theme.custom.margin_right):0)}vw;
  margin-top: ${({ theme }) => theme.custom.resize_screen ?theme.custom.margin_top:0}vw;
  margin-left: ${({ theme }) => theme.custom.resize_screen ?theme.custom.margin_left:0}vw;
  margin-right: ${({ theme }) => theme.custom.resize_screen ?theme.custom.margin_right:0}vw;
  margin-bottom: ${({ theme }) => theme.custom.resize_screen ?theme.custom.margin_bottom:0}vw;
`;

const ScreenContent = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => (theme.custom.default_width) ?theme.custom.max_width+'vw':pixToRem(1024)};
  width: 100%;
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,25))};
  font-weight: ${({ theme }) => (percentPix(theme.custom.font_size,400))};
`;
