export const percentPix = (px: number, percent: number) => (px * percent / 100);

export const percentPixToRem = (px: number, percent: number) => (px * percent / 100 / 16)+'rem';

export const percentPixToRemList = (percent: number,...list: number[]) =>
    list.map((px) => `${percentPixToRem(px, percent)}`).join(" ");

export const pixToRem = (...list: number[]) =>
  list.map((px) => `${px / 16}rem`).join(" ");

export const colorShade = (color: string, percent: number) => {
  var num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = ((num >> 8) & 0x00ff) + amt,
    G = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1)
  );
};
