import { Button } from "../../../components/Button";
import { DurationInput } from "../../../components/DurationField";
import { InputField } from "../../../components/InputField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Col, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";

export const FormTraining = ({
  hideScreenButtons,
}: {
  hideScreenButtons?: boolean;
}) => {
  const { data, actions } = useLocalstorageProvider();

  const matchData = data?.matchData;

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Échauffement</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button
              icon="screen"
              onClick={() => actions.showScreen("screen_training")}
            />
          </Shrink>
        )}
      </Grid>
      <Grid>
        <Col md={9}>
          <InputField
            value={matchData?.training_title || ""}
            label="Titre de l'écran"
            type="text"
            handleChange={(e: any) =>
              actions.handleMatchChange(e.target.value, "training_title")
            }
          />
        </Col>
        <Col md={3}>
          <DurationInput
            buttonIncrement={15}
            minValue={15}
            maxValue={60 * 60 - 1}
            label="Durée"
            value={
              matchData?.training_duration_override ||
              matchData?.training_duration ||
              0
            }
            onChange={(value) =>
              actions.handleMatchChange(value, "training_duration_override")
            }
          />
        </Col>
      </Grid>
    </>
  );
};
