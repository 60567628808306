import styled from "styled-components";
import { pixToRem } from "../styles/theme.mixins";

export const Modal = ({ children, closeModal, width = 500 }: any) => {
  return (
    <>
      <ModalWrapper width={width}>
        <CloseButton onClick={closeModal}>
          <Icon className="icon icon-close" />
        </CloseButton>
        {children}
      </ModalWrapper>
      <Backdrop />
    </>
  );
};

const ModalWrapper = styled.div<{ width: number }>`
  background: ${({ theme }) => theme.colors.body};
  border-radius: ${pixToRem(15)};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  left: 50%;
  width: ${(props) => pixToRem(props.width)};
  overflow-y: auto;
  max-height: 100%;
  padding: ${pixToRem(30)};
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.7);
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const CloseButton = styled.button`
  background: none;
  border: 0;
  position: absolute;
  right: ${pixToRem(10)};
  top: ${pixToRem(10)};
`;

const Icon = styled.i`
  font-size: ${pixToRem(22)};
`;
