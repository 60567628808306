import { ReactNode } from "react";
import styled from "styled-components";
import { pixToRem } from "../../../styles/theme.mixins";

export const AccordionItem = ({
  children,
  title,
  isActive,
  setActive,
}: {
  children: ReactNode | ReactNode[];
  title: string;
  isActive: boolean;
  setActive: any;
}) => {
  return (
    <AccordionWrapper isActive={isActive}>
      <div>
        <AccordionButton isActive={isActive} onClick={setActive}>
          {title}
        </AccordionButton>
      </div>
      {isActive && <AccordionContent>{children}</AccordionContent>}
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled.div<{ isActive: boolean }>`
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.bodySecondary : theme.colors.bodySecondary};
  border: 3px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.brand : theme.colors.bodySecondary};

  border-radius: ${pixToRem(15)};
  margin-bottom: ${pixToRem(5)};
  overflow: hidden;
`;

const AccordionButton = styled.button<{ isActive: boolean }>`
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.brand : "transparent"};
  border: none;
  border-width: 0 3px 3px;
  color: ${({ theme }) => theme.colors.text};
  display: block;
  font-size: ${pixToRem(25)};
  font-weight: bold;
  padding: ${pixToRem(10, 15)};
  text-align: left;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.body : theme.colors.brand};
  }
`;

const AccordionContent = styled.div`
  border-top: 3px solid ${({ theme }) => theme.colors.brand};
`;
