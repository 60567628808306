import styled, { useTheme } from "styled-components";
import { pixToRem, colorShade, percentPixToRemList } from "../../../styles/theme.mixins";
import { TeamType } from "../../../type";
import { Digit } from "./Digit";

export const TeamScoreContent = ({
  name,
  team,
  penalities,
  score,
  hidePenalities,
}: {
  name: string;
  team: TeamType;
  penalities: string[],
  score: number;
  hidePenalities?: boolean;
}) => {
  const { custom } = useTheme();

  const givenPoints = Math.floor(penalities?.length / 3) || 0;

  const scoreStr = score.toString();
  const scoreDecimal = parseInt(scoreStr[score > 9 ? 0 : 1]);
  const scoreUnit = parseInt(scoreStr[score > 9 ? 1 : 0]);

  console.log(custom);

  return (
    <TeamContent>
      <Score>
        <TeamName>{name}</TeamName>
        <ScoreStyled layout={custom?.use_layout}>
          <Digit value={scoreDecimal} size={1.2} customColor={custom.color_main} />
          <Digit value={scoreUnit} size={1.2} customColor={custom.color_main} />
        </ScoreStyled>

        {!hidePenalities ? (
          <TeamPenalities>
            <IconPenality
              className="icon icon-angry"
              active={penalities?.length % 3 > 0}
            />
            <IconPenality
              className="icon icon-angry"
              active={penalities?.length % 3 > 1}
            />
            <IconPenality
              className="icon icon-angry"
              active={penalities?.length % 3 > 2}
            />
            {givenPoints > 0 && <Bonus>&nbsp; +{givenPoints}</Bonus>}
          </TeamPenalities>
        ) : (
          ""
        )}
      </Score>
    </TeamContent>
  );
};

const TeamContent = styled.div`
  text-align: center;
  text-transform: uppercase;
`;

const ScoreStyled = styled.div<{ layout: string }>`
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,20, 0))};
  transform: scale(${({ layout }) => (layout === "2" ? 0.9 : 1)});
`;

const TeamName = styled.div`
  align-items: center;
  border-bottom: 1px solid
    ${({ theme }) => colorShade(theme.custom.color_foreground, -20)};
  display: flex;
  flex: 1 1 0;
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,30))};
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,20, 10))};
`;

const TeamPenalities = styled.div`
  align-items: center;
  border-top: 1px solid
    ${({ theme }) => colorShade(theme.custom.color_foreground, -20)};
  display: flex;
  font-size: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,30))};
  justify-content: center;
  padding: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,20, 10))};
`;

const IconPenality = styled.i<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.danger : colorShade(theme.custom.color_background, 3)};
  margin: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,5))};
`;

const Bonus = styled.div`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: bold;
`;

const Score = styled.div`
  background-color: ${({ theme }) =>
    colorShade(theme.custom.color_background, 10)};
  border-radius: ${pixToRem(15)};
  display: block;
  line-height: 0;
  margin: ${({ theme }) => (percentPixToRemList(theme.custom.font_size,30, 0))};
  overflow: hidden;
`;
