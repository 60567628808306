import { Button } from "../../../components/Button";
import { DurationInput } from "../../../components/DurationField";
import { useLocalstorageProvider } from "../../../hooks/LocalstorageProvider";
import { Auto, Grid, Shrink } from "../../../styles/components/Grid";
import { SectionTitle } from "../styles/SectionStyles";
import {InputField} from "../../../components/InputField";
import {THEME_YES_NO} from "../../../constants";
import {SelectField} from "../../../components/SelectField";

export const FormCaucus = ({ hideScreenButtons }: { hideScreenButtons?: boolean }) => {
  const { data, actions } = useLocalstorageProvider();

  const matchData = data?.matchData;
  const improData = data?.improData;

  return (
    <>
      <Grid vCenter="center">
        <Auto>
          <SectionTitle>Caucus</SectionTitle>
        </Auto>
        {!hideScreenButtons && (
          <Shrink>
            <Button icon="screen" onClick={() => actions.showScreen("screen_caucus")} />
          </Shrink>
        )}
      </Grid>
      <Grid vCenter="center">
          <Shrink>
              <InputField
                  label="Titre"
                  type="text"
                  value={improData?.caucus_title || ""}
                  handleChange={(e: any) => actions.handleImproChange(e.target.value, "caucus_title")}
              />
          </Shrink>
          <Auto>
              <DurationInput
                  buttonIncrement={15}
                  minValue={0}
                  maxValue={60 * 60 - 1}
                  label="Durée caucus"
                  value={improData?.caucus_duration || matchData?.caucus_duration || 20}
                  onChange={(value) => actions.handleImproChange(value, "caucus_duration")}
              />
          </Auto>
          <Shrink>
              <SelectField
                  value={improData?.caucus_useChrono || "Oui"}
                  choices={THEME_YES_NO}
                  label="Chrono"
                  handleChange={(e: any) => actions.handleImproChange(e.target.value, "caucus_useChrono")}
              />
          </Shrink>
          <Shrink>
            <SelectField
                value={improData?.caucus_useChrono_impro || "Oui"}
                choices={THEME_YES_NO}
                label="Chrono des impro"
                handleChange={(e: any) => actions.handleImproChange(e.target.value, "caucus_useChrono_impro")}
            />
          </Shrink>
      </Grid>
    </>
  );
};
